import React, { useEffect } from "react";
import "./LandlordsPage.scss";
import { HeroFullPage } from "../../components/Hero/Hero";
import { duringLetting, heroLandlords, preLetting } from "../../data/general";
import { NavLink } from "react-router-dom";
import { backgroundImg, lettingProcedure, lettingVacant } from "../../assets/assetsExport";
import { Helmet } from "react-helmet";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

const background = { src: backgroundImg.livingRoom2, alt: "background" };
export default function LandlordsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Landlords | Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our services for landlords."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, london, rent, landlords, services, to let, to rent, apartments, house"
        />
      </Helmet>
      <div className="landlords-page-container">
        <HeroFullPage
          img={{ src: background.src, alt: background.alt }}
          h1={heroLandlords.h1}
          intro={heroLandlords.intro}
          learnMore={heroLandlords.learnMore}
        />

        <div className="services-container pre bg-light" id="pre-letting">
          <Nav selected="pre" />
          <div className="content">
            <h2 className="cl-dark">{preLetting.header}</h2>
            <p className="sub cl-main fs-i">{preLetting.subheader}</p>
            <div className="services grid-2">
              {preLetting.content.map((i) => {
                return (
                  <SlideUpAnimation triggerOnce={true}>
                    <Service
                      header={i.header}
                      content={i.p}
                      color={["cl-dark", "cl-dark"]}
                    />
                  </SlideUpAnimation>
                );
              })}
            </div>
          </div>
        </div>
        <div className="services-container during bg-dark" id="during-letting">
          <Nav selected="during" />
          <div className="content">

            <h2 className="cl-main">{duringLetting.header}</h2>
            <p className="sub cl-white fs-i">{duringLetting.subheader}</p>
            <div className="services grid-2 cl-light">
              {duringLetting.content.map((i) => {
                return (
                  <SlideUpAnimation triggerOnce={true}>
                    <Service
                      header={i.header}
                      content={i.p}
                      color={["cl-main", "cl-white"]}
                    />
                  </SlideUpAnimation>
                );
              })}
            </div>
          </div>
        </div>
        <div className="price-list bg-white cl-dark block">
          <h2>Letting Procedures and Price lists</h2>
          <p>
            <a href={lettingProcedure} target="_blank" className=" fw-700">
              Letting procedure and price list
            </a>
          </p>
          <p>
            <a href={lettingVacant} target="_blank" className=" fw-700">
              Vacant property management price list
            </a>
          </p>
        </div>
        <div className="guides bg-dark block">
          <h2 className="cl-main">Landlord Guides</h2>
          <p className="cl-white"><a href="https://www.gov.uk/renting-out-a-property" target="_blank">Renting out your property</a></p>
          <p className="cl-white"><a href="https://www.gov.uk/right-to-manage-a-guide-for-landlords" target="_blank">Right to Manage: a guide for landlords</a></p>
        </div>
      </div>
    </>

  );
}

function Nav(props) {
  const handleScrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav a-no-decor">
      <NavLink className={props.selected === "pre" ? "selected" : ""} to="/services/landlords#pre-letting" onClick={() => { handleScrollToSection("pre-letting"); }}>{preLetting.header}</NavLink>
      <span className="fw-200" style={{ opacity: "0.5" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <NavLink className={props.selected === "during" ? "selected" : ""} to="/services/landlords#during-letting" onClick={() => { handleScrollToSection("during-letting"); }}>{duringLetting.header}</NavLink>
    </div>
  );
}

function Service(props) {
  return (
    <div className="service">
      <h3>{props.header}</h3>
      <p>{props.content}</p>
    </div>
  );
}
