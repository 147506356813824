import React from "react";
import "./Properties.scss";
import { featuredProperties } from "../../data/general";
import Property from "./Property";
import { NavLink } from "react-router-dom";

export default function Properties(props) {
  return (
    <div className={`properties-container ${props.theme}`}>
      <h2>{props.featured && "Featured"} Properties</h2>
      <div className="properties">
        {featuredProperties.map((property, index) => {
          if (index < props.length) {
            return (
              <Property
                img={{ src: property.img.src, alt: property.img.alt }}
                tag={property.tag}
                info={{
                  name: property.info.name,
                  location: property.info.location,
                  type: property.info.type,
                  bedroom: property.info.bedroom,
                  bathroom: property.info.bathroom,
                  price: property.info.price,
                }}
                link={property.link}
                letAgreed={property.tag === "Let Agreed"}
              />
            );
          }
        })}
      </div>
      {props.more && (
        <NavLink className="button a-no-decor" to="/services/rent">
          Learn More
        </NavLink>
      )}
    </div>
  );
}
