import React from 'react';
import './DashboardPage.scss';
import { Helmet } from 'react-helmet';

export default function DashboardPage() {
    return (
        <>
            <Helmet>
                <title></title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"dashboard-page"}>

            </div>
        </>
    );
}