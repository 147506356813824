import React, { useEffect } from 'react';
import './CareersPage.scss';
import { HeroHalfPage } from '../../components/Hero/Hero';
import { backgroundImg } from '../../assets/assetsExport';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function CareersPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Helmet>
                <title>Careers | Letting Engine</title>
                <meta
                    name="description"
                    content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about how to join us and work for us."
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, career, openings, application, job, work, join our team, join us, work for us"
                />
            </Helmet>
            <div className={"careers-page-container"}>
                <HeroHalfPage
                    h1="Join Our Team at Letting Engine"
                    intro="Are you passionate about the luxury property market? Do you thrive in a dynamic and client-focused environment? Letting Engine, a leading residential property letting agency in London, is always looking for talented individuals to join our team. If you are driven, detail-oriented, and dedicated to providing exceptional service, we invite you to explore career opportunities with us."
                    img={{ src: backgroundImg.meeting2, alt: "" }}
                />
                <div className='content bg-light cl-dark'>
                    <h2>Why Work with Us?</h2>
                    <p>At <NavLink to="/">Letting Engine</NavLink>, we believe that our success is driven by our people. We foster a collaborative and supportive work culture where each team member is valued and given opportunities to grow both professionally and personally. When you join Letting Engine, you become part of a team that is committed to excellence, innovation, and delivering the highest standards of service in the industry.</p>
                    <h2>Current Openings</h2>
                    <h3 className='cl-main'>Property Management Executive</h3>
                    <h4>Responsibilities:</h4>
                    <ul>
                        <li>Conduct property viewings and showcase luxury rental properties to potential tenants</li>
                        <li>Negotiate contract terms and conditions in the best interest of both landlords and tenants</li>
                        <li>Prepare rental agreements and other necessary documentation</li>
                        <li>Provide exceptional customer service and build strong relationships with clients</li>
                        <li>Manage a portfolio of luxury rental properties, ensuring efficient operations and maintenance</li>
                        <li>Coordinate property inspections, repairs, and maintenance tasks</li>
                        <li>Respond promptly to tenant inquiries, concerns, and maintenance requests</li>
                        <li>Conduct regular property visits to ensure compliance with tenancy agreements</li>
                        <li>Coordinate move-in and move-out processes</li>
                        <li>Oversee rent collection, financial reporting, and renting renewals</li>
                        <li>Keep accurate records and maintain property management CRM system</li>
                        <li>Handle administrative tasks such as scheduling, filing, and data entry</li>
                        <li>Stay updated on market trends, property values, and rental regulations</li>
                    </ul>
                    <h4>Requirements:</h4>
                    <ul>
                        <li>Minimum of a Bachelor's degree</li>
                        <li>Experience in property management, preferably in the luxury rental market</li>
                        <li>Strong communication and negotiation skills</li>
                        <li>Good knowledge of the London property market</li>
                        <li>Proven ability to build and maintain client relationships</li>
                        <li>Excellent organizational and time management abilities</li>
                        <li>Strong organizational and problem-solving skills</li>
                        <li>Member of ARLA is a plus</li>
                        <li>Proficiency in MRI property management tool is a plus</li>
                    </ul>
                    <h2>How to Apply</h2>
                    <p>To apply for any of the above positions or to express your interest in joining the Letting Engine team, please send your resume and a cover letter to <a href="mailto:hr@lettingengine.com">hr@lettingengine.com</a>.  Please specify the position you are applying for in the subject line. We appreciate all applications, but please note that only shortlisted candidates will be contacted.</p>
                </div>
            </div>
        </>

    );
}