import React from "react";

export default function Property(props) {
  return (
    <a
      className={`property-container ${props.link ? "link" : ""} ${
        props.letAgreed ? "text-gray-500" : "text-gray-900"
      }`}
      href={props.link ? props.link : "#"}
      target={props.link ? "_blank" : "_self"}
      onClick={props.link ? undefined : (e) => e.preventDefault()}
    >
      <div className="img">
        <img src={props.img.src} alt={props.img.alt} />
        {props.tag && <p className="tag">{props.tag}</p>}
      </div>
      <div className="text">
        <div>
          <p className="name">{props.info.name}</p>
          <p className="location">{props.info.location}</p>
        </div>
        <div className="bottom flex ">
          <div className="features flex flex-col sm:flex-row">
            <div className="feature">
              <span className="material-symbols-outlined">home</span>
              <span>{props.info.type}</span>
            </div>
            <div className="feature">
              <span className="material-symbols-outlined">king_bed</span>
              <span>{props.info.bedroom}</span>
            </div>
            <div className="feature">
              <span className="material-symbols-outlined">bathtub</span>
              <span>{props.info.bathroom}</span>
            </div>
          </div>
          <p className="price">
            {props.info.price}
            <span>&nbsp;pcm</span>
          </p>
        </div>
      </div>
    </a>
  );
}
