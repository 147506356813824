import React from "react";
import "./Hero.scss";
import SlideUpAnimation from "../SlideUpAnimation/SlideUpAnimation";

export function HeroFullPage(props) {
  return (
    <div className="hero full-page">
      <div className="background cl-light">
        <div>
          <SlideUpAnimation triggerOnce={false}>
            <h1>{props.h1}</h1>
          </SlideUpAnimation>
          <SlideUpAnimation triggerOnce={false} delay={0.25}>
            <p className="intro">{props.intro}</p>
          </SlideUpAnimation>
          {props.learnMore && (
            <SlideUpAnimation triggerOnce={false} delay={0.5}>
              <p className="learn-more cl-main">{props.learnMore}</p>
              <div className="expand-icon cl-main" onClick={() => {
                window.scrollTo({
                  top: window.innerHeight,
                  behavior: 'smooth',
                });
              }}>
                <span className="material-symbols-outlined">
                  expand_circle_down
                </span>
              </div>
            </SlideUpAnimation>
          )}
        </div>
        <img src={props.img.src} alt={props.img.alt} />
      </div>
    </div>
  );
}

export function HeroHalfPage(props) {
  return (
    <div className="hero half-page">
      <div className="background">
        <div>
          <SlideUpAnimation triggerOnce={false} delay={0}>
            <h1>{props.h1}</h1>
          </SlideUpAnimation>
          <SlideUpAnimation triggerOnce={false} delay={0.25}>
            <p className="intro cl-light">{props.intro}</p>
          </SlideUpAnimation>
        </div>
      </div>
      {/* <p className="learn-more">{props.learnMore}</p>
      <div></div> */}
      <img src={props.img.src} alt={props.img.alt} />
    </div>
  );
}
