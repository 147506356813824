import {
  propertiesImg,
  qualificationLogo,
  rentIllustration,
} from "../assets/assetsExport";

// GENERAL //
export const companyInfo = {
  email: "contact@lettingengine.com",
  phone: "+44 (0)20 3907 4025",
  address: "16 Berkeley Street; Mayfair, London; W1J 8DZ; UK",
};

// ALL HERO CONTEXT //

export const heroHome = {
  h1: "Welcome to Letting Engine",
  intro:
    "Letting Engine is a premier residential property letting agency specialising in luxury rentals in London. Our mission is to provide a seamless and exceptional letting service, offering an exclusive selection of high-end properties in prime locations across the city. With our expertise and dedication, we aim to match discerning tenants with their ideal luxury home.",
};

export const heroLandlords = {
  h1: "Letting Your Luxury Property",
  intro:
    "At Letting Engine, we understand that letting your luxury property requires a strategic approach to attract the right tenants and maximize your return on investment. With our comprehensive landlord services, we take care of every aspect of the letting process, ensuring a hassle-free experience for you.",
  learnMore:
    "Learn more about our landlord services and get started on letting your luxury property today.",
};

export const heroLetting = {
  h1: "To Rent",
  intro:
    "At Letting Engine, we offer an exclusive selection of luxury properties in prime London locations. Whether you're looking for a stylish apartment, a lavish penthouse, or an elegant townhouse, our dedicated team is here to help you find your dream home.",
  learnMore: "Learn more about our letting services and featured properties.",
};

export const heroQualifications = {
  h1: "Qualifications",
  intro:
    "At Letting Engine, we pride ourselves on our exceptional qualifications and commitment to regulatory excellence. As a renowned real estate company, we hold ourselves to the highest standards and diligently adhere to all regulations and industry best practices, to protect both landlords and tenants.",
  learnMore: "Learn more about our qualifications",
};

export const heroAboutUs = {
  h1: "About Us",
  intro:
    "Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. With a prime location in the heart of the city, we offer an extensive portfolio of high-end properties in sought-after neighborhoods. Our dedicated team of experts is committed to delivering exceptional service, providing a seamless letting experience for both landlords and tenants.",
};
export const heroContactUs = {
  h1: "Contact Us",
  intro:
    "Ready to let your luxury property or find your dream rental in London? Get in touch with our team today to discuss your letting needs or schedule a property viewing.",
};

// HOME PAGE //

export const whyUs = [
  {
    header: "Expertise and Experience",
    p: "With years of experience in the luxury property market, we have developed a deep understanding of London's rental landscape. Our knowledgeable team possesses the expertise to guide you through every step of the letting process, ensuring optimal results.",
  },
  {
    header: "Extensive Network",
    p: "Our extensive network of property owners, investors, and high-net-worth individuals allows us to access the most exclusive and desirable properties in London. We offer a curated selection of luxury homes that reflect the unique tastes and preferences of our discerning clientele.",
  },
  {
    header: "Personalized Service",
    p: "We prioritize personalized service and tailor our approach to meet your individual requirements. Our dedicated letting agents are committed to providing exceptional customer service, assisting you at every stage of the letting process and ensuring a smooth and hassle-free experience.",
  },
  {
    header: "Commitment to Quality",
    p: "We maintain uncompromising standards when it comes to the properties we represent. Our meticulous selection process ensures that only the finest residences, with exceptional design, outstanding amenities, and prime locations, are included in our portfolio.",
  },
];

// RENT PAGE //

export const rentingProcess = [
  {
    header: "Property Search",
    p: "Browse our curated collection of high-end properties available for rent.",
    img: {
      src: rentIllustration.search,
      alt: "illustration related to search",
    },
  },
  {
    header: "Property Viewings",
    p: "Schedule property viewings at your convenience and let our experienced agents showcase the unique features and luxurious amenities of each residence.",
    img: {
      src: rentIllustration.viewing,
      alt: "illustration related to viewing",
    },
  },
  {
    header: "Application Process",
    p: "Once you've found your ideal property, we'll guide you through the application process and handle all necessary documentation, ensuring a smooth and efficient leasing experience.",
    img: {
      src: rentIllustration.application,
      alt: "illustration related to application",
    },
  },
  {
    header: "Ongoing Support",
    p: "Our dedicated team is available to assist you throughout your tenancy, providing prompt and reliable support for any inquiries or maintenance requests.",
    img: {
      src: rentIllustration.support,
      alt: "illustration related to support",
    },
  },
];

export const featuredProperties = [
  {
    img: { src: propertiesImg.kidbrookevillageblackheath, alt: "Kidbrooke Village Blackheath flat" },
    tag: "",
    info: {
      name: "Kidbrooke Village Blackheath",
      location: "London, SE3",
      type: "Flat",
      bedroom: "4",
      bathroom: "3",
      price: "£3,185",
    },
    link: "https://www.rightmove.co.uk/properties/154788539#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.damactower9966, alt: "Damac Tower, flat" },
    tag: "",
    info: {
      name: "Damac Tower",
      location: "London, SW8",
      type: "Apartment",
      bedroom: "3",
      bathroom: "3",
      price: "£9,966",
    },
    link: "https://www.rightmove.co.uk/properties/153329498#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.ovalvillage3800, alt: "Oval Village flat" },
    tag: "",
    info: {
      name: "Oval Village",
      location: "London, SE11",
      type: "Apartment",
      bedroom: "2",
      bathroom: "2",
      price: "£3,800",
    },
    link: "https://www.rightmove.co.uk/properties/153746153#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.ovalvillage2800, alt: "Oval Village flat" },
    tag: "",
    info: {
      name: "Oval Village",
      location: "London, SE11",
      type: "Apartment",
      bedroom: "1",
      bathroom: "1",
      price: "£2,800",
    },
    link: "https://www.rightmove.co.uk/properties/153885719#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.ovalvillage2900, alt: "Oval Village flat" },
    tag: "",
    info: {
      name: "Oval Village",
      location: "London, SE11",
      type: "Apartment",
      bedroom: "1",
      bathroom: "1",
      price: "£2,900",
    },
    link: "https://www.rightmove.co.uk/properties/155184032#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.parkdrive3042, alt: "10 Park Drive flat" },
    tag: "",
    info: {
      name: "10 Park Drive",
      location: "London, E14",
      type: "Apartment",
      bedroom: "1",
      bathroom: "1",
      price: "£3,042",
    },
    link: "https://www.rightmove.co.uk/properties/152791034#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.chenistongarden24999, alt: "Cheniston Garden flat" },
    tag: "",
    info: {
      name: "Cheniston Garden",
      location: "London, W8",
      type: "Terraced",
      bedroom: "6",
      bathroom: "6",
      price: "£24,999",
    },
    link: "https://www.rightmove.co.uk/properties/154509314#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.lincolnsquare6656, alt: "Lincoln Square flat" },
    tag: "",
    info: {
      name: "Lincoln Square",
      location: "London, WC2A",
      type: "Apartment",
      bedroom: "2",
      bathroom: "2",
      price: "£6,656",
    },
    link: "https://www.rightmove.co.uk/properties/154946117#/?channel=RES_LET",
  },
  {
    img: { src: propertiesImg.woodberrydown1650, alt: "Woodberry Down flat" },
    tag: "",
    info: {
      name: "Woodberry Down",
      location: "London, Anax Street",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£1,650",
    },
    link: "https://www.rightmove.co.uk/properties/154788866#/?channel=RES_LET",
  },

  {
    img: { src: propertiesImg.harcourt, alt: "Harcourt Tower flat" },
    tag: "",
    info: {
      name: "Harcourt Tower",
      location: "London, E14",
      type: "Flat",
      bedroom: "2",
      bathroom: "1",
      price: "£3,467",
    },
    link: "https://www.rightmove.co.uk/properties/152223470#/?channel=RES_LET",
  },




  ////////////////////////////////



  {
    img: { src: propertiesImg.empire, alt: "805, Empire House flat" },
    tag: "Let Aggreed",
    info: {
      name: "805, Empire House",
      location: "London, W4",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£3,700",
    },
    link: ""
  },
  {
    img: { src: propertiesImg.blackfriars3000, alt: "Blackfriars Road flat" },
    tag: "Let Aggreed",
    info: {
      name: "Blackfriars Road",
      location: "London, SE1",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,000",
    },
    link: ""
  },
  {
    img: { src: propertiesImg.darter2350, alt: "Darter Apartments flat" },
    tag: "Let Aggreed",
    info: {
      name: "Darter Apartments",
      location: "London, N4",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£2,350",
    },
    link: ""
  },

  {
    img: { src: propertiesImg.handley, alt: "Handley Drive flat" },
    tag: "Let Aggreed",
    info: {
      name: "Handley Drive",
      location: "London, SE3",
      type: "Flat",
      bedroom: "4",
      bathroom: "3",
      price: "£3,458",
    },
    link: ""
  },

  {
    img: { src: propertiesImg.hartingtons, alt: "Hartingtons Court flat" },
    tag: "Let Agreed",
    info: {
      name: "Hartingtons Court",
      location: "London, N4",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£2,500",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.oncleveland, alt: "On Cleveland flat" },
    tag: "Let Aggreed",
    info: {
      name: "On Cleveland",
      location: "London, W1T",
      type: "Apartment",
      bedroom: "3",
      bathroom: "2",
      price: "£7,930",
    },
    link: "",
  },









  {
    img: { src: propertiesImg.conquest, alt: "Conquest Tower flat" },
    tag: "Let Agreed",
    info: {
      name: "Conquest Tower, Blackfriars Road",
      location: "London, SE1",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,237",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.onetheelephant, alt: "One The Elephant, St. Gabriel Walk flat" },
    tag: "Let Agreed",
    info: {
      name: "One The Elephant, St. Gabriel Walk",
      location: "London, SE1",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,094",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.darter, alt: "Darter Apartments flat" },
    tag: "Let Agreed",
    info: {
      name: "Darter Apartments",
      location: "London, N4",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£2,500",
    },
    link: "",
  },

  {
    img: { src: propertiesImg.viaductgardens4333, alt: "Dingley Road flat" },
    tag: "Let Agreed",
    info: {
      name: "Viaduct Gardens",
      location: "London, SW11",
      type: "Apartment",
      bedroom: "2",
      bathroom: "2",
      price: "£4,225",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.paragonsquare, alt: "Paragon Square flat" },
    tag: "Let Agreed",
    info: {
      name: "Paragon Square",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£5,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.hemlock,
      alt: "Hemlock Street flat",
    },
    tag: "Let Agreed",
    info: {
      name: "Hemlock Street",
      location: "London, E14",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£2,500",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.millbank60002,
      alt: "Millbank flat",
    },
    tag: "Let Agreed",
    info: {
      name: "Millbank",
      location: "London, SW1P",
      type: "Apartment",
      bedroom: "2",
      bathroom: "2",
      price: "£6,000",
    },
    link: "",
  },

  {
    img: {
      src: propertiesImg.jubilee3500,
      alt: "Jubilee Walk flat",
    },
    tag: "Let Agreed",
    info: {
      name: "Jubilee Walk",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,500",
    },
    link: "",
  },

  {
    img: {
      src: propertiesImg.clunymews4500,
      alt: "3 Cluny Mews flat",
    },
    tag: "Let Agreed",
    info: {
      name: "3 Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,500",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.clunymews3500,
      alt: "3 Cluny Mews flat",
    },
    tag: "Let Agreed",
    info: {
      name: "3 Cluny Mews",
      location: "London, SW5",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,500",
    },
    link: "",
  },











  {
    img: {
      src: propertiesImg.panpeninsula,
      alt: "Pan Peninsula Square flat",
    },
    tag: "Let Agreed",
    info: {
      name: "Pan Peninsula Square",
      location: "London, E14",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,030",
    },
    link: "",
  },

  {
    img: { src: propertiesImg.viaductgardens4500, alt: "Viaduct Gardens flat" },
    tag: "Let Agreed",
    info: {
      name: "Viaduct Gardens",
      location: "London, SW11",
      type: "Apartment",
      bedroom: "2",
      bathroom: "2",
      price: "£4,500",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.marshwall, alt: "Marsh Wall flat" },
    tag: "Let Agreed",
    info: {
      name: "Marsh Wall",
      location: "London, E14",
      type: "Flat",
      bedroom: "2",
      bathroom: "1",
      price: "£3,500",
    },
    link: "",
  },






  {
    img: { src: propertiesImg.jubileewalk3300, alt: "Jubilee Walk flat" },
    tag: "Let Agreed",
    info: {
      name: "Jubilee Walk",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,300",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.jubileewalk3500, alt: "Jubilee Walk flat" },
    tag: "Let Agreed",
    info: {
      name: "Jubilee Walk",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,500",
    },
    link: ""
  },
  {
    img: { src: propertiesImg.mountpleasant, alt: "8 Mount Pleasant flat" },
    tag: "Let Agreed",
    info: {
      name: "8 Mount Pleasant",
      location: "London, WC1X 0BU",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,500",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.paragonsquare5000, alt: "Paragon Square flat" },
    tag: "Let Agreed",
    info: {
      name: "Paragon Square",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£5,000",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.coster, alt: "Coster Avenue flat" },
    tag: "Let Agreed",
    info: {
      name: "Coster Avenue",
      location: "London, N4",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£3,163",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.sunstreet, alt: "Sun Street flat" },
    tag: "Let Agreed",
    info: {
      name: "Sun Street",
      location: "London, EC2A",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,683",
    },
    link: "",
  },

  {
    img: { src: propertiesImg.dingley, alt: "Dingley Road flat" },
    tag: "Let Agreed",
    info: {
      name: "Dingley Road",
      location: "London, EC1V",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,200",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.basil, alt: "a picture of basil street flat" },
    tag: "Let Agreed",
    info: {
      name: "Basil Street",
      location: "London, SW3",
      type: "Flat",
      bedroom: "4",
      bathroom: "5",
      price: "£16,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.parkside,
      alt: "a picture of Parkside Apartments",
    },
    tag: "Let Agreed",
    info: {
      name: "Parkside Apartments",
      location: "London, W12",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£2,600",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.clunymews3200, alt: "a picture of Cluny Mews" },
    tag: "Let Agreed",
    info: {
      name: "Cluny Mews",
      location: "London, SW5 9AG",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,100",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.jubilee3300, alt: "a picture of Jubilee Walk" },
    tag: "Let Agreed",
    info: {
      name: "Jubilee Walk",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,300",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.jubilee3000, alt: "a picture of Jubilee Walk" },
    tag: "Let Agreed",
    info: {
      name: "Jubilee Walk",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.salutation,
      alt: "a picture of Salutation Gardens",
    },
    tag: "Let Agreed",
    info: {
      name: "Salutation Gardens",
      location: "London, WC1X",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,200",
    },
    link: "",
  },

  // {
  //   img: { src: propertiesImg.copse, alt: "a picture of 39 Copse Hill" },
  //   tag: "",
  //   info: {
  //     name: "39 Copse Hill",
  //     location: "London, Wimbledon, SW20 0NJ",
  //     type: "Detached",
  //     bedroom: "5",
  //     bathroom: "6",
  //     price: "£16,000",
  //   },
  //   link: "https://www.rightmove.co.uk/properties/140603696#/?channel=RES_LET"
  // },

  {
    img: {
      src: propertiesImg.studiowhitecity,
      alt: "a picture of Studio, White City Living",
    },
    tag: "Let Agreed",
    info: {
      name: "Studio, White City Living",
      location: "London, W12 7RD",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£2,800",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.allium1730,
      alt: "a picture of Allium House apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Allium House, 2 Caldon Boulevard",
      location: "London, Wembley, HA0 1BD",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£1,730",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.legacy, alt: "a picture of Legacy Building" },
    tag: "Let Agreed",
    info: {
      name: "Legacy Building",
      location: "Viaduct Gardens, London, SW11",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,500",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.bentinck, alt: "a picture of Bentinck Road" },
    tag: "Let Agreed",
    info: {
      name: "Bentinck Road",
      location: "UB7",
      type: "Apartment",
      bedroom: "2",
      bathroom: "1",
      price: "£1,790",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.lincoln, alt: "a picture of Lincoln Apartments" },
    tag: "Let Agreed",
    info: {
      name: "Lincoln Apartments",
      location: "White City Living, London, W12",
      type: "Duplex",
      bedroom: "3",
      bathroom: "4",
      price: "£7,998",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.thenova, alt: "a picture of The Nova Building" },
    tag: "Let Agreed",
    info: {
      name: "The Nova Building",
      location: "London, SW1W 0AJ",
      type: "Flat",
      bedroom: "3",
      bathroom: "2",
      price: "£6,498",
    },
    link: "",
  },

  {
    img: {
      src: propertiesImg.blackfriars3200,
      alt: "a picture of Blackfriars Road",
    },
    tag: "Let Agreed",
    info: {
      name: "Blackfriars Road",
      location: "London, SE1",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,200",
    },
    link: "",
  },
  {
    img: { src: propertiesImg.clunymews4200, alt: "a picture of 3 Cluny Mews" },
    tag: "Let Agreed",
    info: {
      name: "3 Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,200",
    },
    link: "",
  },

  {
    img: {
      src: propertiesImg.allium,
      alt: "a picture of Allium House apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Allium House, 8 Belgrave Road",
      location: "London, Wembley",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£1,800",
    },
    link: "",
  },

  {
    img: { src: propertiesImg.parrs, alt: "a picture of Parrs Way apartment" },
    tag: "Let Agreed",
    info: {
      name: "Parrs Way",
      location: "London, W6",
      type: "Apartment",
      bedroom: "1",
      bathroom: "1",
      price: "£2,950",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.blackfriars,
      alt: "a picture of Blackfriars Road apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Blackfriars Road",
      location: "London, SE1",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,400",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.markville,
      alt: "a picture of Markville Gardens apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Markville Gardens, Caterham",
      location: "Surrey, CR3",
      type: "Detached",
      bedroom: "4",
      bathroom: "3",
      price: "£3,950",
    },
    link: "",
  },

  {
    img: {
      src: propertiesImg.one3700,
      alt: "a picture of One Cluny Mews apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "One Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,700",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.one4500,
      alt: "a picture of One Cluny Mews apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "One Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,500",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.one4200,
      alt: "a picture of One Cluny Mews apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "One Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£4,200",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.one3300,
      alt: "a picture of One Cluny Mews apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "One Cluny Mews",
      location: "London, SW5 9EG",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.moor15000,
      alt: "a picture of Moor Lane apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Moor Lane",
      location: "London, EC2Y",
      type: "Flat",
      bedroom: "3",
      bathroom: "3",
      price: "£15,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.moor17117,
      alt: "a picture of Moor Lane apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Moor Lane",
      location: "London, EC2Y",
      type: "Flat",
      bedroom: "3",
      bathroom: "3",
      price: "£17,117",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.millbank6000,
      alt: "a picture of 9 Millbank apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "9 Millbank",
      location: "London SWIP",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£6,000",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.millbank6200,
      alt: "a picture of 9 Millbank apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "9 Millbank",
      location: "London, SW1P",
      type: "Flat",
      bedroom: "2",
      bathroom: "2",
      price: "£6,200",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.blenheim,
      alt: "a picture of Blenheim Mansions apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "Blenheim Mansions, 3 Mary Neuner Road",
      location: "London, N8",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£1,750",
    },
    link: "",
  },
  {
    img: {
      src: propertiesImg.mount,
      alt: "a picture of 8 Mount Pleasant apartment",
    },
    tag: "Let Agreed",
    info: {
      name: "8 Mount Pleasant",
      location: "London, WC1X 0BU",
      type: "Flat",
      bedroom: "1",
      bathroom: "1",
      price: "£3,400",
    },
    link: "",
  },
];

// LANDLORDS PAGE //
export const preLetting = {
  header: "Pre Letting",
  subheader: "Find a Good Tenant",
  content: [
    {
      header: "Initial Consultation:",
      p: "Conducting an initial consultation with landlords to understand their specific needs, requirements, and expectations.",
    },
    {
      header: "Property Evaluation: ",
      p: "Our experienced team will conduct a thorough evaluation of your property to determine its rental potential and advise on any enhancements that can increase its desirability.",
    },
    {
      header: "Marketing and Advertising: ",
      p: "Creating compelling property listings with professional photography and descriptions to attract a wide pool of qualified tenants. Utilizing various marketing channels, such as Rightmove, Zoopla, Red and WeChat, to maximize exposure.",
    },
    {
      header: "Targeted Marketing: ",
      p: "Leveraging our extensive network and innovative marketing strategies, we'll promote your property to qualified tenants, ensuring maximum exposure and minimizing vacancies.",
    },
    {
      header: "Tenant Screening and Selection: ",
      p: "We conduct a rigorous tenant screening process, including comprehensive background checks, credit checks, employment verification, financial assessments, and references to ensure that responsible and trustworthy tenants are selected.",
    },
    {
      header: "Contract Negotiation: ",
      p: "Our skilled negotiators handle tenancy contract negotiations on your behalf, ensuring favourable terms and conditions while protecting your interests.",
    },
    {
      header: "Tenancy Agreement and Documentation: ",
      p: "Preparing and facilitating the signing of legally compliant lease agreements and tenancy documentation, ensuring that all necessary terms and conditions are clearly outlined and agreed upon.",
    },
    {
      header: "Legal Compliance: ",
      p: "Staying up-to-date with relevant landlord and tenant legislation, regulations, and legal requirements to ensure full compliance. Providing guidance to landlords on their legal responsibilities and obligations.",
    },
  ],
};

export const duringLetting = {
  header: "Services During Letting",
  subheader: "We take care of everything",
  content: [
    {
      header: "Check-in and Check-out Inventory: ",
      p: "Providing comprehensive inventory services to landlords, ensuring accurate documentation of the property's condition at the start and end of a tenancy. The detailed inventory report provides a clear record of the property's condition, helping to avoid disputes between landlords and tenants regarding deposit deductions for damages.",
    },
    {
      header: "Property Maintenance and Repairs:",
      p: "Coordinating and overseeing property maintenance, repairs, and inspections as needed. Responding promptly to tenant maintenance requests and ensuring that the property is well-maintained throughout the tenancy.",
    },
    {
      header: "Cleaning Services:",
      p: "To ensure that the property is presented in its best condition for new tenants, we arrange professional cleaning services to create a welcoming environment for tenants and to maintain the property's overall hygiene.",
    },
    {
      header: "Rent Collection and Financial Management: ",
      p: "Managing the collection and processing of rent payments, ensuring timely and accurate accounting. Providing landlords with detailed financial statements and rent payment reports.",
    },
    {
      header: "Tax Consultation: ",
      p: "Offering comprehensive tax consultation services to ensure that landlords understand their tax obligations, optimize their tax position, and maintain compliance with UK tax laws, thereby maximizing financial outcomes while minimizing any potential tax risks or issues. Providing guidance on the Non-Resident Landlord Scheme for overseas landlords.",
    },
    {
      header: "Tenant Relations: ",
      p: "Facilitating effective communication between landlords and tenants, addressing any concerns or issues that may arise during the tenancy.",
    },
    {
      header: "Communication and Support: ",
      p: "Maintaining open and transparent communication with landlords, providing regular updates on property performance, market trends, and any relevant information. Offering ongoing support and assistance throughout the letting process.",
    },
  ],
};

// ABOUT PAGE //
export const vision =
  "Our vision is to be the leading provider of luxury residential property letting services in London. We strive to set the highest standard in the industry, recognized for our exceptional properties, unparalleled customer service, and innovative approach. Our aim is to exceed the expectations of our clients, build long-lasting relationships, and be the go-to agency for luxury rentals in the city.";

export const mission =
  "Our mission is to connect discerning tenants with their perfect luxury homes while providing comprehensive services that meet the needs of landlords. We are committed to delivering a seamless letting process, offering an exclusive collection of high-end properties, and ensuring utmost satisfaction for both landlords and tenants. With our expertise, dedication, and personalized approach, we aim to make the letting experience exceptional and memorable.";

export const values = [
  {
    header: "Excellence: ",
    p: "We strive for excellence in everything we do, setting the highest standards for service, property selection, and client satisfaction.",
  },
  {
    header: "Integrity: ",
    p: "We conduct our business with utmost integrity, maintaining transparency, honesty, and ethical practices in all our interactions.",
  },
  {
    header: "Customer-Centric: ",
    p: "Our clients are at the heart of everything we do. We prioritize their needs, preferences, and satisfaction, delivering personalized and exceptional service at every step.",
  },
  {
    header: "Expertise: ",
    p: "With our deep knowledge of the London property market and industry expertise, we provide informed guidance, market insights, and valuable advice to our clients.",
  },
  {
    header: "Innovation: ",
    p: "We embrace innovation and stay ahead of industry trends and technologies to continuously enhance our services, improve efficiency, and deliver an exceptional letting experience.",
  },
  {
    header: "Collaboration: ",
    p: "We foster strong partnerships and collaborations with landlords, tenants, and industry professionals, working together to achieve mutual success and satisfaction.",
  },
  {
    header: "Trust:",
    p: "Trust is the foundation of our relationships. We aim to build trust through open communication, reliability, and delivering on our promises.",
  },
];

// QUALIFICATIONS PAGE //
export const qualifications = [
  {
    name: "arla",
    img: { src: qualificationLogo.arla, alt: "ARLA logo" },
    header: "Association Of Residential Letting Agents (ARLA)",
    p: [
      "<p>Letting Engine is proud to be a member of Association of Residential Letting Agents (ARLA), an organization that upholds the highest standards in the letting industry. As ARLA members, we are committed to providing exceptional service to our landlords and tenants. Our dedicated lettings staff undergo comprehensive training and competency tests set by ARLA, ensuring their expertise and knowledge in the field. We take pride in the excellent results they achieve, demonstrating our commitment to delivering top-quality service and adhering to industry best practices.</p>",
    ],
    link: {
      text: "view the Propertymark Conduct and Membership Rules",
      url: "",
    },
  },
  {
    name: "cmp",
    img: { src: qualificationLogo.cmp, alt: "CMP logo" },
    header: "Client Money Protection (CMP)",
    p: [
      '<p>Letting Engine is pleased to announce our membership in the National Federation of Property Professionals Client Money Protection (CMP) Scheme through <a href="https://www.propertymark.co.uk/professional-standards/rules/cmp.html" target="_blank">Propertymark</a>.</p>',
      "<p>This membership highlights our commitment to providing a secure and trustworthy environment for our clients. The CMP Scheme ensures that any client funds entrusted to Letting Engine are handled with utmost responsibility and in compliance with industry regulations. By being a part of the CMP Scheme, we prioritize the protection of client funds and uphold the highest standards of professionalism and transparency in our operations. Clients can have peace of mind knowing that their money is safeguarded under the guidelines of the CMP Scheme when working with Letting Engine.</p>",
      "<p>Propertymark states: CMP is a scheme that reimburses landlords and tenants should a letting agent misappropriate their rent, deposit or other client funds. It has long been a requirement for ARLA Propertymark members to have CMP and has been a legal requirement for all letting agents since 1 April 2019.</p>",
    ],
  },
  {
    name: "prs",
    img: { src: qualificationLogo.prs, alt: "PRS logo" },
    header: "Property Redress Scheme (PRS)",
    p: [
      "<p>Letting Engine is a proud member of the Property Redress Scheme (PRS). As a member, we adhere to the highest standards of professionalism and ethical practices in the property letting industry.</p>",
      "<p>The Property Redress Scheme provides an independent redress mechanism for landlords and tenants, ensuring that any disputes or complaints are handled fairly and efficiently. Our membership with PRS reflects our commitment to resolving any issues transparently and in accordance with industry regulations. We strive to provide our clients with peace of mind, knowing that they are supported by a reputable and accountable letting agency.</p>",
    ],
  },
  {
    name: "nrla",
    img: { src: qualificationLogo.nrla, alt: "NRLA logo" },
    header: "National Residential Landlords Association (NRLA)",
    p: [
      "<p>Letting Engine proudly holds membership with the National Residential Landlords Association (NRLA). As active members, we embrace the NRLA's principles of professionalism, ethical conduct, and compliance within the residential property letting sector.</p>",
      "<p>The NRLA is a leading organization that provides valuable resources, guidance, and support to landlords and letting agencies across the country. Our membership with the NRLA reflects our commitment to staying informed about the latest industry trends, regulations, and best practices. By being a part of the NRLA, Letting Engine demonstrates our dedication to providing exceptional service to our clients while operating in line with the highest industry standards.</p>",
    ],
  },
];
