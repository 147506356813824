import React, { useContext, useEffect } from "react";
import "./HomePage.scss";
import { NavLink } from "react-router-dom";
import { heroHome, whyUs } from "../../data/general";
import { backgroundImg } from "../../assets/assetsExport";
import Properties from "../../components/Property/Properties";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../context/globalContext";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

const homeHeroImg = [
  { src: backgroundImg.livingRoom1, alt: "" },
  { src: backgroundImg.livingRoom4, alt: "" },
  { src: backgroundImg.livingRoom3, alt: "" },
];

export default function HomePage() {
  const { isMobile } = useContext(GlobalContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Contact us, checkout our properties, or learn more about us."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, london, properties, apartments, houses, contact us"
        />
      </Helmet>
      <div className="home-page-container">
        <div className="special-hero bg-light">
          <div>
            <SlideUpAnimation triggerOnce={false}>
              {isMobile ?
                <h1 className="fs-i">Welcome to <br />Letting Engine</h1> :
                <h1 className="fs-i">{heroHome.h1}</h1>}
            </SlideUpAnimation>
            <SlideUpAnimation triggerOnce={false} delay={0.25}>
              <p>{heroHome.intro}</p>
            </SlideUpAnimation>
            <SlideUpAnimation triggerOnce={false} delay={0.5}>
              <div>
                <NavLink className="button fw-400 bg-main cl-dark a-no-decor" to="/services/rent">Browse Properties</NavLink>
                <NavLink className="button fw-400 bg-dark cl-white a-no-decor" to="/contact-us">Contact Us</NavLink>
              </div>
            </SlideUpAnimation>
          </div>
          <div className="imgs">
            {homeHeroImg.map((i) => {
              return <img src={i.src} alt={i.alt} />;
            })}
          </div>
        </div>
        <Properties theme="bg-white" more={true} length={6} featured={true} />
        <div className="bg-dark why-us">
          <SlideUpAnimation triggerOnce={false}>
            <h2 className="cl-main">Why Choose Letting Engine?</h2>
          </SlideUpAnimation>
          <div className="grid-2">
            {whyUs.map((i, index) => {
              return (
                <SlideUpAnimation triggerOnce={true} delay={(index + 1) * 0.25}>
                  <div>
                    <h3 className="cl-main">{i.header}</h3>
                    <p className="cl-light">{i.p}</p>
                  </div>
                </SlideUpAnimation>
              );
            })}
          </div>
        </div>
      </div>
    </>

  );
}
