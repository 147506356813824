import React, { useEffect } from "react";
import "./QualificationsPage.scss";
import { HeroFullPage } from "../../components/Hero/Hero";
import { heroQualifications, qualifications } from "../../data/general";
import { NavLink } from "react-router-dom";
import { backgroundImg } from "../../assets/assetsExport";
import { Helmet } from "react-helmet";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

export default function QualificationsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Qualifications | Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our qualifications and certificates."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, london, qualifications, certificates, qualify, legitimacy, law"
        />
      </Helmet>
      <div className="qualifications-page-container">
        <HeroFullPage
          h1={heroQualifications.h1}
          intro={heroQualifications.intro}
          learnMore={heroQualifications.learnMore}
          img={{ src: backgroundImg.livingRoom5, alt: "background" }}
        />
        <div className="qualifications">
          {qualifications.map((i, index) => {
            return (
              <div className={`${index % 2 === 0 ? "bg-light" : "bg-white"}`} id={i.name}>
                <Nav selected={i.name} />
                <SlideUpAnimation triggerOnce={true} delay={0.25}>
                  <div className="qualification">
                    <img src={i.img.src} alt={i.img.alt} />
                    <h2>{i.header}</h2>
                    {i.p.map((p) => {
                      return <p dangerouslySetInnerHTML={{ __html: p }} />;
                    })}
                    {i.link && (
                      <p>
                        Click&nbsp;
                        <a href={i.link.url} target="_blank">
                          here
                        </a>
                        &nbsp;to&nbsp;{i.link.text}.
                      </p>
                    )}
                  </div>
                </SlideUpAnimation>
              </div>
            );
          })}
        </div>
      </div >
    </>
  );
}

function Nav(props) {
  const handleScrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav">
      <NavLink className={props.selected === "arla" && "selected"} to="/qualifications#arla" onClick={() => { handleScrollToSection("arla"); }}>ARLA</NavLink>
      <span>&nbsp;|&nbsp;</span>
      <NavLink className={props.selected === "cmp" && "selected"} to="/qualifications#cmp" onClick={() => { handleScrollToSection("cmp"); }}>CMP</NavLink>
      <span>&nbsp;|&nbsp;</span>
      <NavLink className={props.selected === "prs" && "selected"} to="/qualifications#prs" onClick={() => { handleScrollToSection("prs"); }}>PRS</NavLink>
      <span>&nbsp;|&nbsp;</span>
      <NavLink className={props.selected === "nrla" && "selected"} to="/qualifications#nrla" onClick={() => { handleScrollToSection("nrla"); }}>NRLA</NavLink>
    </div>
  );
}
