import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { logo } from "../assets/assetsExport";

export async function generatePDFDocumentFromRef(elementRef) {
    if (!elementRef) return null;

    const pdfDoc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
    });

    const pdfWidth = pdfDoc.internal.pageSize.getWidth();
    const pdfHeight = pdfDoc.internal.pageSize.getHeight();



    // Add the logo to the top right 590 182
    const logoWidth = 60 / 1.7;
    const logoHeight = 24.4 / 1.7;
    pdfDoc.addImage(logo.dark, 'PNG', pdfWidth - logoWidth - 10, 10, logoWidth, logoHeight);

    return html2canvas(elementRef, {
        scale: 4,
        onclone: (clonedDoc) => {
            // const clonedElement = clonedDoc.querySelectorAll('h3');
            const rentEstimate = clonedDoc.querySelector('.to-print-rent-estimate');
            const stampDuty = clonedDoc.querySelector('.to-print-stamp-duty');
            const rentalYield = clonedDoc.querySelector('.to-print-rental-yield');
            const mortgage = clonedDoc.querySelector('.to-print-mortgage');

            if (rentEstimate) {
                rentEstimate.style.setProperty("width", "800px", "important");
            }
            if (stampDuty) {
                stampDuty.style.setProperty("width", "750px", "important");
            }
            if (rentalYield) {
                rentalYield.style.setProperty("width", "600px", "important");
            }
            if (mortgage) {
                mortgage.style.setProperty("width", "600px", "important");
            }
            const flexRows = clonedDoc.querySelectorAll(".return-flex-row");
            if (flexRows && flexRows.length > 0) {
                flexRows.forEach(element => {
                    // element.style.setProperty('padding-bottom', '30px', 'important');
                    element.style.setProperty("flex-direction", "row", "important");
                });
            }

            const clonedMortgageTable = clonedDoc.querySelector(".mortgage-result-table");
            if (clonedMortgageTable) {
                clonedMortgageTable.style.display = 'none';
            }

            const tds = clonedDoc.querySelectorAll('td, th');
            const labels = clonedDoc.querySelectorAll('label>span');
            const textInputs = clonedDoc.querySelectorAll('input[type="text"]');
            const h3 = clonedDoc.querySelectorAll('h3');
            const buttons = clonedDoc.querySelectorAll('button');
            const specialH1 = clonedDoc.querySelector('.special-h1');
            const poundSigns = clonedDoc.querySelectorAll('.adj');

            if (specialH1) {
                specialH1.style.setProperty("margin-bottom", "120px", "important");
            }

            if (tds && tds.length > 0) {
                tds.forEach(element => {
                    // element.style.setProperty('padding-bottom', '30px', 'important');
                    element.style.transform = "translateY(-7.5px)";
                });

            }

            if (labels && labels.length > 0) {
                labels.forEach(element => {
                    element.style.setProperty('padding-bottom', '15px', 'important');
                });
            }

            if (textInputs && textInputs.length > 0) {
                textInputs.forEach(element => {
                    element.style.setProperty("height", '37.5px', "important");
                    element.style.setProperty("padding-bottom", '10px', "important");
                });
            }

            if (poundSigns && poundSigns.length > 0) {
                poundSigns.forEach(element => {
                    element.style.setProperty("height", '37.5px', "important");
                    element.style.setProperty("padding-bottom", '7.5px', "important");
                });
            }

            if (h3 && h3.length > 0) {
                h3.forEach(element => {
                    element.style.setProperty("padding-bottom", '20px', "important");
                });
            }
            if (buttons && buttons.length > 0) {
                buttons.forEach(element => {
                    element.style.setProperty("display", 'none', "important");
                });
            }
        }
    }).then((canvas) => {
        const canvasAspectRatio = canvas.height / canvas.width;
        console.log(canvas);
        const pdfAspectRatio = pdfHeight / pdfWidth;

        let canvasHeight, canvasWidth;
        if (canvasAspectRatio > 1) {
            canvasHeight = pdfHeight * 0.85;
            canvasWidth = canvasHeight / canvasAspectRatio;
            if (canvasWidth > pdfWidth) {
                canvasWidth = pdfWidth * 0.85;
                canvasHeight = canvasWidth * canvasAspectRatio;
            }
        } else {
            canvasWidth = pdfWidth * 0.85;
            canvasHeight = canvasWidth * canvasAspectRatio;
            if (canvasHeight > pdfHeight) {
                canvasHeight = pdfHeight * 0.85;
                canvasWidth = canvasHeight / canvasAspectRatio;
            }
        }

        let marginLeft = (pdfWidth - canvasWidth) / 2;

        // Add the logo at the top right
        const imageData = canvas.toDataURL('image/png');
        pdfDoc.addImage(imageData, 'PNG', marginLeft, logoHeight + 15, canvasWidth, canvasHeight); // Adjust the Y position to account for the logo

        // Add the footer text at the bottom center
        const footerText = "16 Berkeley Street, Mayfair, London, W1J 8DZ, United Kingdom      Tel: +44(0)20 3907 4025      www.lettingengine.com";
        const fontSize = 10; // Adjust the font size as needed
        pdfDoc.setFontSize(fontSize);
        const textWidth = pdfDoc.getStringUnitWidth(footerText) * fontSize / pdfDoc.internal.scaleFactor;
        const textX = (pdfWidth - textWidth) / 2; // Center the text
        const textY = pdfHeight - 8; // Position the text 10mm from the bottom
        pdfDoc.text(footerText, textX, textY);
        return pdfDoc;
    });

    // return html2canvas(elementRef, { scale: 4 }).then((canvas) => {
    //     // return html2canvas(elementRef, { scale: 4, windowHeight: document.body.scrollHeight }).then((canvas) => {
    //     const imageData = canvas.toDataURL();
    //     const imgWidth = pdfDoc.internal.pageSize.getWidth();
    //     const imgHeight = canvas.height * imgWidth / canvas.width;

    //     pdfDoc.addImage(imageData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, "FAST");
    //     return pdfDoc;
    // });
}