import React, { useEffect } from 'react';
import './Policies.scss';
import { Helmet } from 'react-helmet';

export function PoliciesCookies() {
    return (
        <>
            <Helmet>
                <title>Cookies Policy | Letting Engine</title>
                <meta
                    name="description"
                    content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our cookies policy."
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, cookies policy, law"
                />
            </Helmet>
            <Policy>
                <div className={"policy-container policies-cookies-container"}>
                    <h1>Cookies Policy</h1>
                    <p>This Cookie Policy explains how Letting Engine ("we," "us," or "our") uses cookies and similar tracking technologies when you visit our website. By using our website, you consent to the use of cookies as described in this policy.</p>
                    <ul>
                        <li>
                            <h2>1. What are Cookies?</h2>
                            Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They enable the website to remember your actions and preferences (such as login details, language, font size, and other display preferences) over a certain period. Cookies serve various purposes, including making the website more user-friendly, analyzing website usage, and providing personalized content.
                        </li>
                        <li>
                            <h2>2. Types of Cookies We Use</h2>
                            <ul>
                                <li>2.1. Necessary Cookies: These cookies are essential for the operation of our website. They enable you to navigate the site, use its features, and access secure areas. Without these cookies, certain services may not be available to you.</li>
                                <li>2.2. Analytics Cookies: We use analytics cookies to collect information about how visitors use our website, such as the pages visited, the time spent on each page, and the referring website. This information helps us understand and improve the performance of our website.</li>
                                <li>2.3. Preference Cookies: Preference cookies are used to remember your preferences and settings on the website, such as language preferences and display settings. They enhance your browsing experience and make your interactions with the website more personalized.</li>
                                <li>2.4. Marketing Cookies: We may use marketing cookies to track your interactions with our website and display targeted advertisements based on your interests. These cookies may be set by us or by third-party advertising partners.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>3. Third-Party Cookies</h2>
                            <p>We may also allow third-party service providers, such as analytics or advertising companies, to place cookies on our website. These third parties may collect information about your online activities over time and across different websites. We do not have control over these third-party cookies or their privacy practices. We recommend reviewing the respective privacy policies of these third parties.</p>
                        </li>
                        <li>
                            <h2>4. Cookie Consent and Control</h2>
                            <p>By using our website, you consent to the placement of cookies on your device as described in this Cookie Policy. You can control and manage cookies in various ways. Most web browsers allow you to accept or reject cookies or notify you when a cookie is being sent. However, please note that disabling or blocking certain cookies may impact the functionality and performance of the website.</p>
                        </li>
                        <li>
                            <h2>5. Updates to the Cookie Policy</h2>
                            <p>We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. Any changes will be posted on this page with the updated "Last Updated" date. We encourage you to review this Cookie Policy periodically for any updates.</p>
                        </li>
                        <li>
                            <h3>Contact Us</h3>
                            <p>If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at <a href="mailto:contact@lettingengine.com">contact@lettingengine.com</a>.</p>
                        </li>
                    </ul>
                </div>
            </Policy>
        </>
    );
}

export function PoliciesPrivacy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Letting Engine</title>
                <meta
                    name="description"
                    content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our privacy policy."
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, privacy policy, law"
                />
            </Helmet>
            <Policy>
                <div className={"policy-container policies-privacy-container"}>
                    <h1>Privacy Policy</h1>
                    <p>At Letting Engine, we are committed to protecting the privacy and personal information of our website visitors, clients, and tenants. This Privacy Policy outlines how we collect, use, disclose, and protect the information we gather through our website and in the course of providing our services. By accessing or using our website or engaging with our services, you consent to the terms outlined in this Privacy Policy.</p>
                    <ul>
                        <li>
                            <h2>Information Collection</h2>
                            <p>We may collect the following types of information:</p>
                            <ul>
                                <li>Personal Information: When you voluntarily provide it to us, we may collect personal information such as your name, email address, phone number, and any other information you provide when contacting us or using our services.</li>
                                <li>Website Usage Information: We may collect non-personal information about your interactions with our website, including IP addresses, browser details, device information, and website activity. This information helps us understand how visitors engage with our website and improve our services.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>Information Use</h2>
                            <p>We may use the collected information for the following purposes:</p>
                            <ul>
                                <li>Providing Services: To respond to your inquiries, provide requested services, and communicate with you regarding property listings, appointments, or other relevant matters.</li>
                                <li>Marketing and Communication: To send you marketing communications, newsletters, updates, or other promotional materials that may be of interest to you. You can opt-out of these communications at any time.</li>
                                <li>Improving Our Services: To analyze website usage patterns, gather feedback, and enhance our website and services based on user preferences and needs.</li>
                                <li>Legal Compliance: To comply with legal obligations, enforce our terms and conditions, protect our rights, and address any potential fraud or security issues.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>Information Sharing and Disclosure</h2>
                            <p>We may share your information in the following circumstances:</p>
                            <ul>
                                <li>Service Providers: We may engage trusted third-party service providers who assist us in delivering our services. These service providers have access to the necessary information to perform their functions but are obligated to maintain the confidentiality and security of your information.</li>
                                <li>Legal Compliance: We may share your information when required by law, court order, or legal process, or if we believe in good faith that such disclosure is necessary to protect our rights, safety, or the rights of others.</li>
                                <li>Business Transfers: In the event of a merger, acquisition, or transfer of assets, your information may be transferred as part of the transaction. We will take appropriate measures to ensure the continued protection of your information.</li>
                            </ul>

                        </li>
                        <li>
                            <h2>Data Security</h2>
                            <p>We employ appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                        </li>
                        <li>
                            <h2>Data Retention</h2>
                            <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                        </li>
                        <li>
                            <h2>Your Rights and Choices</h2>
                            <p> You have the right to access, correct, update, or delete your personal information, subject to any legal requirements. You may also have the right to restrict or object to certain processing activities. To exercise these rights or make any inquiries regarding your personal information, please contact us using the information provided below.</p>
                        </li>
                        <li>
                            <h2>Third-Party Links</h2>
                            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
                        </li>
                        <li>
                            <h2>Updates to Privacy Policy</h2>
                            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically to stay informed of any updates.</p>
                        </li>
                        <li>
                            <h2>Contact Us</h2>
                            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us:</p>
                            <h3>Letting Engine</h3>
                            <p>16 Berkeley Street</p>
                            <p>Mayfair</p>
                            <p>London, W1J 8DZ</p>
                            <p>UK</p>
                            <p>Email: <a href="mailto:contact@lettingengine.com">contact@lettingengine.com</a></p>
                            <p>Office: +44 (0)20 3907 4025</p>

                            <p>We are committed to addressing your inquiries and resolving any concerns to ensure the protection of your privacy.</p>
                        </li>
                    </ul>
                </div>
            </Policy>
        </>
    );
}

export function PoliciesTerms() {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | Letting Engine</title>
                <meta
                    name="description"
                    content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our terms and conditions."
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, terms and conditions, law"
                />
            </Helmet>
            <Policy>
                <div className={"policy-container policies-terms-container"}>
                    <h1>Terms and Conditions</h1>
                    <p>These Terms and Conditions ("Terms") govern your access to and use of the Letting Engine website ("www.lettingengine.com"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using the Website.</p>
                    <ul>
                        <li>
                            <h2>1. Website Use</h2>
                            <ul>
                                <li>1.1. Eligibility: You must be at least 18 years old and have the legal capacity to enter into a binding agreement to use the Website.</li>
                                <li>1.2. Permitted Use: You agree to use the Website solely for lawful purposes and in accordance with these Terms. Any unauthorized use of the Website is strictly prohibited.</li>
                                <li>1.3. Account Creation: Some features or services on the Website may require you to create an account. You are responsible for providing accurate and up-to-date information during the account creation process and maintaining the confidentiality of your account credentials.</li>
                                <li>1.4. User Content: You may have the opportunity to submit or post content on the Website. By doing so, you grant Letting Engine a non-exclusive, worldwide, royalty-free, perpetual, and transferable license to use, reproduce, modify, adapt, distribute, and display the content for the purposes of operating and improving the Website.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>2. Intellectual Property</h2>
                            <ul>
                                <li>2.1. Ownership: The Website and its contents, including but not limited to text, graphics, logos, images, and software, are the property of Letting Engine and are protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Website without prior written consent from Letting Engine.</li>
                                <li>2.2. Trademarks: All trademarks, service marks, and logos displayed on the Website are the property of Letting Engine or their respective owners. You are prohibited from using any trademarks or logos without the prior written permission of the owner.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>3. Disclaimer and Limitation of Liability</h2>
                            <ul>
                                <li>3.1. Website Content: The information provided on the Website is for general informational purposes only. While we strive to ensure the accuracy and timeliness of the content, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information.</li>
                                <li>3.2. Third-Party Links: The Website may contain links to third-party websites. These links are provided for your convenience, but we do not endorse or have control over the content, privacy policies, or practices of these websites. We are not responsible for any loss or damage incurred as a result of your use of any third-party website.</li>
                                <li>3.3. Limitation of Liability: To the fullest extent permitted by law, Letting Engine and its affiliates, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the Website.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>4. Privacy Policy</h2>
                            <p>Your use of the Website is subject to our Privacy Policy [hyperlink]. By using the Website, you consent to the collection, use, and disclosure of your information as described in the Privacy Policy.</p>
                        </li>
                        <li>
                            <h2>5. Modifications to the Terms</h2>
                            <p>Letting Engine reserves the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Website after any modifications to the Terms constitutes your acceptance of the revised Terms.</p>
                        </li>
                        <li>
                            <h2>6. Governing Law and Jurisdiction</h2>
                            <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].</p>

                        </li>
                        <li>
                            <h2>Contact Us</h2>
                            <p>If you have any questions, concerns, or feedback regarding these Terms, please contact us at contact@lettingengine.com.</p>
                        </li>
                    </ul>

                </div>
            </Policy>
        </>
    );
}

function Policy(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return props.children;
}