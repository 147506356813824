import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { generatePDFDocumentFromRef } from "../../../util/generatePDF";
import { GlobalContext } from "../../../context/globalContext";
import LanguageToggle from "../../../components/LanguageToggle/LanguageToggle";



const PropertyType = {
    "flat": "公寓",
    "terraced_house": "联排别墅",
    "semi-detached_house": "半独立式房屋",
    "detached_house": "独立式房屋"
};


export default function RentEstimatePage() {
    const [inputs, setInputs] = useState({
        postcode: "W1J 8DZ",
        bedrooms: 1,
        type: "flat",
        // points: 20
    });

    const [result, setResult] = useState({ status: "null" });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const { isSmallScreen } = useContext(GlobalContext);
    const tableRef = useRef(null);
    const [isEnglish, setIsEnglish] = useState(new URLSearchParams(window.location.search).get('language') === 'chinese' ? false : true);

    async function handleEstimateRent(e) {
        e.preventDefault();
        setError("");
        setLoading(true);
        if (!inputs.postcode.replaceAll(" ", "")) return;
        try {
            const response15 = await fetch(`https://api.propertydata.co.uk/rents?key=0YU2HWR2QR&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=15`);
            const response100 = await fetch(`https://api.propertydata.co.uk/rents?key=0YU2HWR2QR&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=100`);
            // const response = await fetch(`https://api.propertydata.co.uk/rents?key=${process.env.PROPERTYDATA_KEY}&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=${inputs.points}`);
            const result = [await response15.json(), await response100.json()];
            // Math.min(...result.data)
            if (result[0].status === "success" && result[1].status === "success") {
                const data = [result[0].data.long_let.average, result[1].data.long_let.average];
                setResult({
                    status: "success",
                    data: [Math.min(...data), Math.max(...data)]
                });
            } else if (result[0].status === "error") {
                setError(result[0].message);
            } else if (result[1].status === "error") {
                setError(result[1].message);
            }
        } catch (error) {
            console.error("Error fetching rental estimate:", error);
            setError("There was an error fetching rental estimate, please try again later.");
        } finally {
            setLoading(false);
        }
    }

    function _getChineseType(input) {
        // This function now correctly maps the English property type to Chinese
        return isEnglish ? input : PropertyType[input];
    }

    function handleSaveThisSearch() {

    }

    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('language', isEnglish ? 'english' : 'chinese');
        window.history.pushState({}, '', url);
    }, [isEnglish]);

    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                const doc = await generatePDFDocumentFromRef(tableRef.current);
                if (doc) {
                    doc.save(`Rental Income Estimate.pdf`);
                    // const pdfBlob = doc.output('blob');
                    // const pdfUrl = URL.createObjectURL(pdfBlob);
                    // setPdfUrl(pdfUrl);

                    // !isSmallScreen && window.open(pdfUrl, '_blank');

                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF, tableRef.current]);



    return (
        <>
            <Helmet>
                <title>Rent Estimate | Letting Engine</title>
                <meta
                    name="description"
                    content="Use our rent estimator for your property. Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. "
                />
                <meta
                    name="keywords"
                    content="residential property letting agency, real estate, london, rent estimate, rent"
                />
            </Helmet>
            <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} />
            <div className={`flex flex-col items-center ${isEnglish ? " isEnglish" : "isChinese"}`}>

                <div ref={tableRef} className={`content-inner p-10 sm:p-16 to-print-rent-estimate`}>
                    <form
                        onSubmit={handleEstimateRent}
                        onChange={() => { setError(""); setResult({ status: "null" }); setPdfUrl(""); }}
                        className="flex flex-col gap-10 text-secondary-dark"
                    >
                        <h1 className="mb-1 sm:mb-10 special-h1">
                            <span className="english">Estimate Rental Income</span>
                            <span className="chinese">估算租金收入</span>
                        </h1>
                        <label className="flex sm:items-center gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Postcode:</span>
                                <span className="chinese">邮政编码:</span>
                            </span>
                            <input
                                className="input input-bordered input-warning sm:w-full sm:max-w-xs bg-transparent"
                                type="text"
                                value={inputs.postcode}
                                placeholder="W1J 8DZ"
                                onChange={(e) => setInputs({ ...inputs, postcode: e.target.value })}
                            />
                        </label>
                        <div className="flex gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Bedrooms:</span>
                                <span className="chinese">卧室:</span>
                            </span>
                            {[0, 1, 2, 3, 4, 5].map((bedroom) => (
                                <label key={bedroom} className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm radio-warning"
                                        type="radio"
                                        name="bedrooms"
                                        value={bedroom}
                                        checked={inputs.bedrooms === bedroom}
                                        onChange={() => setInputs({ ...inputs, bedrooms: bedroom })}
                                    />
                                    <span>{bedroom}</span>
                                </label>
                            ))}
                        </div>
                        <div className="flex gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Property Type:</span>
                                <span className="chinese">物业类型:</span>
                            </span>
                            {Object.keys(PropertyType).map((type) => (
                                <label key={type} className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm radio-warning"
                                        type="radio"
                                        name="type"
                                        value={type}
                                        checked={inputs.type === type}
                                        onChange={() => setInputs({ ...inputs, type: type })}
                                    />
                                    <span>{_getChineseType(type).replace(/_/g, ' ')}</span>
                                    {/* <span>{type.replace(/_/g, ' ')}</span> */}
                                </label>
                            ))}
                        </div>
                        <button className="btn btn-outline btn-warning pt-0 mt-0" type="submit" disabled={!inputs.postcode.replaceAll(" ", "")}>
                            {loading ?
                                (
                                    <>
                                        <span className="english">Estimating...</span>
                                        <span className="chinese">为您估算中...</span>
                                    </>
                                )
                                : (
                                    <>
                                        <span className="english">Estimate</span>
                                        <span className="chinese">估算</span>
                                    </>
                                )}
                        </button>
                    </form>
                    <div className="mt-16 flex flex-col">
                        {error && <p className="font-semibold mb-4 text-main">{error}</p>}
                        {result && result.status === "success" && result.data &&
                            <>
                                <h3 className="font-semibold mb-4 text-main">
                                    <span className="english">Estimated Rent:</span>
                                    <span className="chinese">估计租金:</span>
                                    £{formatMonthlyPrice(result.data[0])} <span>{isEnglish ? "to" : "至"}</span> £{formatMonthlyPrice(result.data[1])} <span>{isEnglish ? "per month" : "每月"}</span>
                                </h3>
                                <p className="font-sans text-base text-secondary-dark">
                                    <span className="english">A {inputs.type} at {inputs.postcode} with {inputs.bedrooms} bedroom{inputs.bedrooms > 1 && "s"} has an average rent of £{result.data[0].toLocaleString()} to £{result.data[1].toLocaleString()} per week, £{formatMonthlyPrice(result.data[0])} to £{formatMonthlyPrice(result.data[1])} per month.</span>
                                    <span className="chinese">一个位于 {inputs.postcode} 的{_getChineseType(inputs.type).replace(/_/g, ' ')}，有{inputs.bedrooms}间卧室，平均租金为每周£{result.data[0].toLocaleString()}至£{result.data[1].toLocaleString()}，即每月£{formatMonthlyPrice(result.data[0])}至£{formatMonthlyPrice(result.data[1])}。</span>
                                </p>
                                <div className='mt-20 mb-20 opacity-50'>
                                    <p>{isEnglish ? "The rent estimate is calculated using big data to average the rents of similar listings around the postal code, and is for reference only. Actual rent may vary based on property condition, floor, and available facilities." : "预估租金计算根据大数据对邮编周围类似房源的租金取平均值，仅供参考。具体租金跟房况，楼层，配套设施等多种因素有关。"}</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {!error && result && result.status === "success" && result.data &&
                    <div className="mb-20 flex flex-col items-center ">
                        <button className="bg-main px-4 py-2 rounded-lg mt-12 self-center mb-10 sm:mb-60 text-secondary-dark" onClick={handleDownloadPDF}>
                            {isGeneratingPDF ?
                                (
                                    <>
                                        <span className="english">Downloading PDF...</span>
                                        <span className="chinese">PDF 下载中...</span>
                                    </>
                                )
                                : (
                                    <>
                                        <span className="english">Download PDF</span>
                                        <span className="chinese">下载 PDF</span>
                                    </>
                                )}
                        </button>
                        {isSmallScreen && pdfUrl && (
                            <div className="pdf-download-link">
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    <span className="english">Open PDF</span>
                                    <span className="chinese">打开 PDF</span>
                                </a>
                            </div>
                        )}
                    </div>
                }
            </div>
        </>

    );
}

function formatMonthlyPrice(price) {
    return (price * 52 / 12).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}