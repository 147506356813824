import React, { useContext, useEffect, useState } from "react";
import "./Header.scss";
import { NavLink, useLocation } from "react-router-dom";
import { logo } from "../../assets/assetsExport";
import { GlobalContext } from "../../context/globalContext";

export default function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [addBackground, setAddBackground] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isSmallScreen } = useContext(GlobalContext);
  const location = useLocation();
  const [expandListStates, setExpandListStates] = useState({});

  const toggleExpandList = (listName) => {
    setExpandListStates((prevStates) => ({
      ...prevStates,
      [listName]: !prevStates[listName],
    }));
  };

  const closeAllExpandLists = () => {
    setExpandListStates({});
  };

  useEffect(() => {
    const toggleBackground = () => {
      if (document.querySelector("body").getBoundingClientRect().top < -20) {
        setAddBackground(true);
      } else {
        setAddBackground(false);
      }
    };

    window.addEventListener("scroll", toggleBackground);

    return () => {
      window.removeEventListener("scroll", toggleBackground);
    };
  }, []);

  function toggleMenu() {
    if (!menuIsOpen) {
      console.log(window);
      console.log(window.scrollY);
      document.querySelector("html").classList.add("noscroll");
      document.querySelector("body").classList.add("noscroll");
      // document.querySelector("body").style.overflow = "visible";
      // document.querySelector("body").style.height = "100%";

      // setScrollPosition(window.scrollY);
    } else {
      console.log(scrollPosition);
      document.querySelector("html").classList.remove("noscroll");
      document.querySelector("body").classList.remove("noscroll");
      // document.querySelector("html").style.overflow = "visible";
      // document.querySelector("body").style.overflow = "visible";
      // document.querySelector("body").style.height = "auto";
      // window.scrollTo(0, scrollPosition);
      closeAllExpandLists();
    }

    setMenuIsOpen(!menuIsOpen);
  }

  function closeMenu(e) {
    if (e.target.tagName === "A") {
      // document.querySelector("html").style.overflow = "visible";
      document.querySelector("html").classList.remove("noscroll");
      document.querySelector("body").classList.remove("noscroll");
      setMenuIsOpen(false);
      closeAllExpandLists();
    }
  }

  function isSelected(path) {
    return location.pathname === path ? "selected" : "";
  }

  // function logoIsDark() {
  //   const path = ["/", "/policies/cookies-policy", "/policies/privacy-policy", "/policies/terms-and-conditions"];
  //   return path.includes(location.pathname);
  // }

  function logoIsDark() {
    const pathname = location.pathname;
    const staticPaths = [
      "/",
      "/policies/cookies-policy",
      "/policies/privacy-policy",
      "/policies/terms-and-conditions",
      "/services/stamp-duty-calculator",
      "/services/rent-estimate",
      "/services/rental-yield",
      "/services/mortgage-calculator",
    ];
    const dynamicPathsRegex = [/^\/rent\/properties\/property\/\d+$/];
    return (
      staticPaths.includes(pathname) ||
      dynamicPathsRegex.some((regex) => regex.test(pathname))
    );
  }

  return (
    // <header className={`header-container ${addBackground && "background"}`} onClick={() => {
    <header
      className={`header-container ${
        isSmallScreen
          ? menuIsOpen
            ? " small-screen open"
            : "small-screen close"
          : ""
      } ${addBackground ? "background" : ""}`}
      onClick={(e) => {
        if (e.target.tagName === "A") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }}
    >
      <div>
        <NavLink to="/" onClick={closeMenu}>
          <img
            src={
              !logoIsDark() || menuIsOpen || addBackground
                ? logo.light
                : logo.dark
            }
            alt="Letting Engine logo in yellow and white"
          />
        </NavLink>

        {isSmallScreen && (
          <span
            className="material-symbols-outlined menu-icon"
            onClick={toggleMenu}
          >
            {menuIsOpen ? "close" : "menu"}
          </span>
        )}
      </div>
      {/* <ul className={`header-list a-no-decor ${(isSelected("/") || isSelected("/careers") || isSelected("/about-us")) ? "dark" : ("light")}`} onClick={closeMenu}> */}
      <ul
        className={`header-list a-no-decor ${
          isSelected("/") || isSelected("/careers") || isSelected("/about-us")
            ? "dark"
            : isSelected("/contact-us")
            ? "light"
            : ""
        }`}
        onClick={closeMenu}
      >
        <li className={`header-item ${isSelected("/services/rent")}`}>
          <NavLink to="/services/rent">Rent</NavLink>
          {/* <ul className="sub-header-list">
            <li className={`sub-header-item ${isSelected("/services/rent/properties")}`}>
              <NavLink to="/services/rent/properties">Properties</NavLink>
            </li>

          </ul> */}
        </li>
        <ExpandList
          to="/services/landlords"
          header="Landlords"
          list={
            <>
              <li
                className={`sub-header-item ${isSelected(
                  "/services/stamp-duty-calculator"
                )} expand-list`}
              >
                <NavLink to="/services/stamp-duty-calculator?language=english">
                  Stamp Duty Calculator
                </NavLink>
              </li>
              <li
                className={`sub-header-item ${isSelected(
                  "/services/rental-yield"
                )} expand-list`}
              >
                <NavLink to="/services/rental-yield?language=english">
                  Rental Yield
                </NavLink>
              </li>
              <li
                className={`sub-header-item ${isSelected(
                  "/services/rent-estimate"
                )} expand-list`}
              >
                <NavLink to="/services/rent-estimate?language=english">
                  Rent Estimator
                </NavLink>
              </li>
              <li
                className={`sub-header-item ${isSelected(
                  "/services/mortgage-calculator"
                )} expand-list`}
              >
                <NavLink to="/services/mortgage-calculator?language=english">
                  Mortgage Calculator
                </NavLink>
              </li>
            </>
          }
          isSelected={isSelected}
          isOpen={expandListStates["landlords"]}
          toggleExpandList={() => toggleExpandList("landlords")}
        />
        {/* <li className={`header-item ${isSelected("/services/landlords")}`}>
          {isSmallScreen
            ?
            <div className="flex items-center justify-center gap-10">
              <NavLink to="/services/landlords">Landlords</NavLink><IsOpen />
            </div>

            :
            <NavLink to="/services/landlords">Landlords</NavLink>

          }
          <ul className="sub-header-list">
            <li className={`sub-header-item ${isSelected("/services/stamp-duty-calculator")}`}>
              <NavLink to="/services/stamp-duty-calculator">Stamp Duty Calculator</NavLink>
            </li>
            <li className={`sub-header-item ${isSelected("/services/rent/yield")}`}>
              <NavLink to="/services/rental-yield">Rental Yield</NavLink>
            </li>
            <li className={`sub-header-item ${isSelected("/services/rent/estimate")}`}>
              <NavLink to="/services/rent-estimate">Rent Estimator</NavLink>
            </li>
          </ul>
        </li> */}
        <li className={`header-item ${isSelected("/qualifications")}`}>
          <NavLink to="/qualifications">Qualifications</NavLink>
        </li>
        <li className={`header-item ${isSelected("/about-us")}`}>
          <NavLink to="/about-us">About Us</NavLink>
        </li>
        <li className={`header-item ${isSelected("/contact-us")}`}>
          <NavLink to="/contact-us">Contact Us</NavLink>
        </li>
        <li className={`header-item ${isSelected("/careers")}`}>
          <NavLink to="/careers">Careers</NavLink>
        </li>
        {/* <li className={`header-item ${isSelected("/dashboard")}`}>
          <NavLink to="/dashboard">Dashboard</NavLink>
          <ul className="sub-header-list">
            <li className={`sub-header-item ${isSelected("/dashboard/add-listing")}`}>
              <NavLink to="/dashboard/add-listings">Add a Listing</NavLink>
            </li>
            <li className={`sub-header-item ${isSelected("/dashboard/all-listing")}`}>
              <NavLink to="/dashboard/manage-listings">Manage Listings</NavLink>
            </li>
          </ul>
        </li> */}
      </ul>
    </header>
  );
}

function ExpandList({
  header,
  to,
  list,
  isSelected,
  isOpen,
  toggleExpandList,
}) {
  const { isSmallScreen } = useContext(GlobalContext);
  // const [open, setOpen] = useState(false);
  return (
    <li className={`header-item ${isSelected(to)}`}>
      {isSmallScreen ? (
        <div className="flex items-center justify-center gap-1">
          <NavLink to={to}>{header}</NavLink>
          <span
            onClick={toggleExpandList}
            className="material-symbols-outlined"
          >
            {isOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
      ) : (
        <NavLink to={to}>{header}</NavLink>
      )}
      <ul className={`sub-header-list ${isOpen && "open"}`}>{list}</ul>
    </li>
  );
}
