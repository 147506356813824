import React, { useEffect } from "react";
import "./RentPage.scss";
import { HeroFullPage } from "../../../components/Hero/Hero";
import { featuredProperties, heroLetting, rentingProcess } from "../../../data/general";
import { backgroundImg } from "../../../assets/assetsExport";
import Properties from "../../../components/Property/Properties";
import { Helmet } from "react-helmet";
import SlideUpAnimation from "../../../components/SlideUpAnimation/SlideUpAnimation";

export default function RentPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Rent | Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about our properties and services for tenants."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, london, rent, tenants, to let, to rent, apartments, house"
        />
      </Helmet>
      <div className="rent-page-container">
        <HeroFullPage
          h1={heroLetting.h1}
          intro={heroLetting.intro}
          learnMore={heroLetting.learnMore}
          img={{ src: backgroundImg.livingRoom1, alt: "background" }}
        />
        <div className="renting-process">
          <h2 className="bg-light">Renting Process</h2>
          {rentingProcess.map((i, index) => {
            if (index % 2 === 0) {
              return (
                <div className="bg-light item">
                  <div>
                    <h3>{i.header}</h3>
                    <p>{i.p}</p>
                  </div>
                  <img src={i.img.src} alt={i.img.alt} />
                </div>
              );
            } else {
              return (
                <div className="bg-white item">
                  <img src={i.img.src} alt={i.img.alt} />
                  <div>
                    <h3>{i.header}</h3>
                    <p>{i.p}</p>
                  </div>
                </div>
              );
            }
          })}

          <div className="begin bg-light fs-i">
            <SlideUpAnimation triggerOnce={false}>
              <p>
                Begin your search for the perfect luxury rental and experience
                unparalleled living in London.
              </p>
            </SlideUpAnimation>
          </div>
        </div>
        <div className="bg-dark block guides">
          <h2 className="cl-main">Tenant Guides</h2>
          <p className="cl-white"><a href="https://www.gov.uk/government/publications/how-to-rent" target="_blank">How to rent</a></p>
          <p className="cl-white"><a href="https://www.gov.uk/council-tax/who-has-to-pay" target="_blank">How Council Tax works</a></p>
        </div>
        <Properties theme="bg-light" length={featuredProperties.length} featured={false} />
      </div>
    </>

  );
}
