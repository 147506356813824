import React, { useEffect } from "react";
import "./ContactUsPage.scss";
import { HeroHalfPage } from "../../components/Hero/Hero";
import { companyInfo, heroContactUs } from "../../data/general";
import { backgroundImg } from "../../assets/assetsExport";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Contact Us | Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals. Learn more about how you can contact us."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, contact, general inquires, property inquires, feedback, careers, london, luxury rentals"
        />
      </Helmet>
      <div className="contact-us-page-container">
        <HeroHalfPage h1={heroContactUs.h1} intro={heroContactUs.intro} img={{ src: backgroundImg.meeting1, alt: "background" }} />
        <div className="content bg-light">
          <div>
            <h2>Contact Information</h2>
            <p>
              Email:{" "}
              <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
            </p>
            <p>Phone: {companyInfo.phone}</p>
            <p>Address: {companyInfo.address.replaceAll(";", ",")}</p>
          </div>
          <div className="flex">
            <div>
              <h2>General Inquiries</h2>
              <p>
                For general inquiries or information about our services, please
                email us at <a href="mailto:contact@lettingengine.com">contact@lettingengine.com</a>, or call our office during
                business hours. Our knowledgeable team is ready to assist you.
              </p>
            </div>
            <div>
              <h2>Property Inquiries</h2>
              <p>
                If you have specific questions or would like to inquire about a
                particular property, please use the contact details provided in
                the property listing or reach out to our dedicated Property
                Management Executive directly. They will be happy to provide you
                with more information or schedule a property viewing.
              </p>
            </div>
          </div>
          <div>
            <h2>Careers</h2>
            <p>
              If you are interested in joining our team, please visit our <NavLink to="/careers">Careers
                page</NavLink> for information about current openings and how to apply. We
              look forward to hearing from you.
            </p>
          </div>
          <div>
            <h2>Feedback</h2>
            <p>
              We value your feedback as it helps us improve our services. If you
              have any suggestions, comments, or concerns, please email us
              at&nbsp;
              <a href="mailto:feedback@lettingengine.com" className="cl-main">
                feedback@lettingengine.com
              </a>
              . We appreciate your input.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
