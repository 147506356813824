import React, { useEffect } from "react";
import "./AboutUsPage.scss";
import { HeroHalfPage } from "../../components/Hero/Hero";
import { heroAboutUs, mission, values, vision } from "../../data/general";
import { NavLink } from "react-router-dom";
import { backgroundImg } from "../../assets/assetsExport";
import { Helmet } from "react-helmet";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>About Us | Letting Engine</title>
        <meta
          name="description"
          content="Letting Engine is a premier residential property letting agency based in London, specializing in luxury rentals."
        />
        <meta
          name="keywords"
          content="residential property letting agency, real estate, london, luxury rentals, rent, landlords, tenants, to let, apartment searching"
        />
      </Helmet>
      <div className="about-us-page-container">
        <HeroHalfPage h1={heroAboutUs.h1} intro={heroAboutUs.intro}
          img={{ src: backgroundImg.social, alt: "background" }} />
        <div className="section vision bg-light" id="vision">
          <Nav selected="vision" />
          <div className="content">
            <SlideUpAnimation triggerOnce={true}>
              <h2 className="cl-g-main">Vision</h2>
            </SlideUpAnimation>
            <SlideUpAnimation triggerOnce={true} delay={0.25}>
              <p className="cl-dark">{vision}</p>
            </SlideUpAnimation>
          </div>
        </div>
        <div className="section mission bg-dark" id="mission">
          <Nav selected="mission" />
          <div className="content">
            <SlideUpAnimation triggerOnce={true}>
              <h2 className="cl-g-main">Mission</h2>
            </SlideUpAnimation>
            <SlideUpAnimation triggerOnce={true} delay={0.25}>
              <p className="cl-light">{mission}</p>
            </SlideUpAnimation>
          </div>
        </div>
        <div className="section values bg-light" id="values">
          <Nav selected="values" />
          <div className="content">
            <SlideUpAnimation triggerOnce={true}>
              <h2 className="cl-g-main">Values</h2>
            </SlideUpAnimation>
            <SlideUpAnimation triggerOnce={true} delay={0.25}>
              <div className="values-container">
                {values.map((i) => {
                  return (
                    <div className="value">
                      <h3 className="cl-main">{i.header}</h3>
                      <p className="cl-dark">{i.p}</p>
                    </div>
                  );
                })}
              </div>
            </SlideUpAnimation>
          </div>
          <p className="legal">Letting Engine is a business name used by New Vision Real Estate Limited, registered in England and Wales. Company No.: 12987204. VAT Number: 386337268.</p>
        </div>
      </div>
    </>

  );
}

function Nav(props) {
  const handleScrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav">
      <NavLink className={props.selected === "vision" && "selected"} to="/about-us#vision" onClick={() => { handleScrollToSection("vision"); }}>Vision</NavLink>
      <span>&nbsp;|&nbsp;</span>
      <NavLink className={props.selected === "mission" && "selected"} to="/about-us#mission" onClick={() => { handleScrollToSection("mission"); }}>Mission</NavLink>
      <span>&nbsp;|&nbsp;</span>
      <NavLink className={props.selected === "values" && "selected"} to="/about-us#values" onClick={() => { handleScrollToSection("values"); }}>Values</NavLink>
    </div>
  );
}
