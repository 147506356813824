import React from 'react';
import './ManageListingPage.scss';
import { Helmet } from 'react-helmet';

export default function ManageListingPage() {
    return (
        <>
            <Helmet>
                <title></title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"manage-listing-page-container"}>

            </div>
        </>
    );
}