import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import HomePage from "../pages/HomePage/HomePage";
import ContactUsPage from "../pages/ContactUsPage/ContactUsPage";
import RentPage from "../pages/RentPages/RentPage/RentPage";
import LandlordsPage from "../pages/LandlordsPage/LandlordsPage";
import QualificationsPage from "../pages/QualificationsPage/QualificationsPage";
import {
  PoliciesCookies,
  PoliciesPrivacy,
  PoliciesTerms,
} from "../pages/Policies/Policies";
import CareersPage from "../pages/CareersPage/CareersPage";
import BannerCookie from "../layouts/BannerCookie/BannerCookie";
import PropertiesPage from "../pages/RentPages/PropertiesPage/PropertiesPage";
import RentYieldPage from "../pages/LandlordsPage/YieldPage/YieldPage";
import DashboardPage from "../pages/UserPages/DashboardPage/DashboardPage";
import AddListingPage from "../pages/UserPages/AddListingPage/AddListingPage";
import ManageListingPage from "../pages/UserPages/ManageListingPage/ManageListingPage";
import StampDutyPage from "../pages/LandlordsPage/StampDutyPage/StampDutyPage";
import RentalYieldPage from "../pages/LandlordsPage/YieldPage/YieldPage";
import RentEstimatePage from "../pages/LandlordsPage/RentEstimatePage/RentEstimatePage";
import MortgagePage from "../pages/LandlordsPage/MortgagePage/MortgagePage";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/qualifications" element={<QualificationsPage />} />
          <Route path="/services/rent" element={<RentPage />} />
          <Route path="/services/landlords" element={<LandlordsPage />} />
          {/* <Route path="/services/rent/properties" element={<PropertiesPage />} /> */}
          <Route path="/services/stamp-duty-calculator" element={<StampDutyPage />} />
          <Route path="/services/rental-yield" element={<RentalYieldPage />} />
          <Route path="/services/rent-estimate" element={<RentEstimatePage />} />
          <Route path="/services/mortgage-calculator" element={<MortgagePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/add-listings" element={<AddListingPage />} />
          <Route path="/dashboard/manage-listings" element={<ManageListingPage />} />
          <Route
            path="/policies/terms-and-conditions"
            element={<PoliciesTerms />}
          />
          <Route
            path="/policies/privacy-policy"
            element={<PoliciesPrivacy />}
          />
          <Route
            path="/policies/cookies-policy"
            element={<PoliciesCookies />}
          />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </main>
      <BannerCookie />
      <Footer />
    </BrowserRouter>
  );
}
