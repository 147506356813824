import './AddListingPage.scss';
import { Helmet } from 'react-helmet';
import React, { useRef, useState } from 'react';
import COS from "cos-js-sdk-v5";
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function AddListingPage() {
    const [postcode, setPostcode] = useState('');
    const [flatHouseNumber, setFlatHouseNumber] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [propertyType, setPropertyType] = useState('Flat');
    const [numBedrooms, setNumBedrooms] = useState('');
    const [numBathrooms, setNumBathrooms] = useState('');
    const [monthlyRent, setMonthlyRent] = useState('');
    const [moveInDate, setMoveInDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [description, setDescription] = useState('');

    const fileInputRef = useRef(null);
    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleAddListing(e) {
        e.preventDefault();
        if (!postcode.trim() ||
            !flatHouseNumber.trim() ||
            !city.trim() ||
            !country.trim() ||
            !propertyType.trim() ||
            isNaN(+numBedrooms) ||
            isNaN(+numBathrooms) ||
            isNaN(+monthlyRent) ||
            !moveInDate.trim() ||
            !description.trim() ||
            selectedFiles.length < 1
        ) { setError("Please fill in all fields"); return; }

        try {
            setLoading(true);
            // if (status === "authenticated" && session.user?.id) {

            const credentialsData = await fetchCosCredentials();
            const credentials = credentialsData.credentials;

            const cos = new COS({
                getAuthorization: function (options, callback) {
                    callback({
                        TmpSecretId: credentials.tmpSecretId,
                        TmpSecretKey: credentials.tmpSecretKey,
                        XCosSecurityToken: credentials.sessionToken,
                        ExpiredTime: credentialsData.expiredTime,
                        StartTime: credentialsData.startTime
                    });
                }
            });

            let urls = [];
            for (const file of selectedFiles) {
                const uploadResult = await uploadToCOS(cos, file);
                urls.push(uploadResult);
                // console.log('Upload result:', uploadResult);
            }

            const formData = {
                userId: "temp", // TODO: change this
                // userId: session.user.id,
                postcode,
                street_name: flatHouseNumber,
                city,
                country,
                type: propertyType,
                bedrooms: Number(numBedrooms),
                bathrooms: Number(numBathrooms),
                price: Number(monthlyRent),
                available_date: new Date(moveInDate + 'T00:00:00').toISOString(),
                description,
            };

            if (urls.length === selectedFiles.length) {
                const response = await axios.post('http://localhost:3000/api/properties', formData);
                if (response.status === 200 && response.data.property_id > 0) {

                    const responseImg = await axios.post(`http://localhost:3000/api/properties/${response.data.property_id}/images`, {
                        "image_urls": urls
                    });

                    if (responseImg.status === 200 && responseImg.data.message === "images added") {
                        setMessage("Successfully adding a listing");
                        setLoading(false);
                        clearForm();
                        setShowSuccessPage(true);
                        console.log('Listing added successfully:', response.data);
                    } else {
                        setError("Error adding listing, please try again later.");
                        new Error("Adding image urls to database failed");
                    }
                } else {
                    setError("Error adding listing, please try again later.");
                    new Error("Creating property failed");
                }
            } else {
                setError("Error adding listing, please try again later.");
                new Error("Uploading images to COS failed");
            }
            // }


            // Add additional logic for the rest of the form data
        } catch (error) {
            setError("Error adding listing, please try again later.");
            console.error('Error handling listing:', error);
        }


    }

    const clearForm = () => {
        setPostcode('');
        setFlatHouseNumber('');
        setCity('');
        setCountry('');
        setPropertyType('Flat');
        setNumBedrooms('');
        setNumBathrooms('');
        setMonthlyRent('');
        setMoveInDate('');
        setSelectedFiles([]);
        setDescription('');
    };

    const fetchCosCredentials = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/cos_credentials');
            return response.data;
        } catch (error) {
            console.error('Error fetching COS credentials:', error);
            throw error;
        }
    };

    const uploadToCOS = async (cosClient, file) => {
        return new Promise((resolve, reject) => {
            const key = `rentrent/${file.name}`;
            cosClient.putObject({
                Bucket: 'rentrent2-1316640881', // Replace with your bucket name
                Region: 'ap-beijing', // Replace with your bucket region
                Key: key, // Replace with your desired file path in COS
                Body: file,
                onProgress: function (progressData) {
                    console.log(JSON.stringify(progressData));
                }
            }, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    // console.log(data);
                    resolve("https://" + data.Location);
                }
            });
        });
    };

    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files) {
    //         const newFilesArray = Array.from(event.target.files);
    //         setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
    //     }
    // };

    const handleChooseFiles = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files) {
            const newFilesArray = Array.from(event.target.files).filter(file => {
                if (file.size > MAX_FILE_SIZE) {
                    alert(`File size should be less than ${MAX_FILE_SIZE / 1024 / 1024} MB`);
                    return false;
                }
                return true;
            });
            setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
        }
    };

    const handleDeleteImage = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    if (showSuccessPage) {
        return <ListingSuccessPage setShowSuccessPage={setShowSuccessPage} />;
    }

    return (
        <>
            <Helmet>
                <title></title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className='add-listing-page-container'>

                <form onSubmit={handleAddListing} className={`form mx-auto text-complementary flex flex-col`} onChange={() => { setError(""); setMessage(""); }}>
                    <h1 className='text-main font-semibold text-4xl font-main text-center mb-12'>Add a Listing</h1>

                    {/* Location Details */}
                    <div className={`section`}>
                        <h2>Location Details</h2>
                        <label>
                            <span>Postcode</span>
                            <input type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                        </label>
                        <label>
                            <span>Flat or House Number (private)</span>
                            <input type="text" value={flatHouseNumber} onChange={(e) => setFlatHouseNumber(e.target.value)} />
                        </label>
                        <label>
                            <span>City</span>
                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                        </label>
                        <label>
                            <span>Country</span>
                            <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                        </label>
                    </div>

                    {/* Property Details */}
                    <div className={`section`}>
                        <h2>Property Details</h2>
                        <label>
                            <span>Property Type</span>
                            <select className='select select-bordered w-full max-w-xs select-sm'
                                value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                                <option value="Flat">Flat</option>
                                <option value="House">House</option>
                            </select>
                        </label>
                        <label>
                            <span>Number of Bedrooms</span>
                            <input type="number" min={0} value={numBedrooms} onChange={(e) => setNumBedrooms(e.target.value)} />
                        </label>
                        <label>
                            <span>Number of Bathrooms</span>
                            <input type="number" min={0} value={numBathrooms} onChange={(e) => setNumBathrooms(e.target.value)} />
                        </label>
                        <label>
                            <span>Description</span>
                            <textarea value={description} onChange={(e) => { setDescription(e.target.value); }} className="textarea textarea-bordered textarea-md w-full max-w-xs" placeholder="Bio"></textarea>
                        </label>
                    </div>

                    {/* Tenancy Details */}
                    <div className={`section`}>
                        <h2>Tenancy Details</h2>
                        <label>
                            <span>Monthly Rent</span>
                            <input type="text" value={monthlyRent} onChange={(e) => setMonthlyRent(e.target.value)} />
                        </label>
                        <label>
                            <span>Earliest Move In Date</span>
                            <input type="date" id="start" name="trip-start" value={moveInDate}
                                onChange={(e) => setMoveInDate(e.target.value)}
                                min={new Date().toISOString().split('T')[0]} max="2025-12-31" />
                        </label>
                    </div>

                    {/* Photo Upload */}
                    <div className={`section`}>
                        <h2>Photos</h2>
                        {/* Handling file uploads can be more complex and might need additional handling */}
                        {/* <input type="file" multiple accept="image/png, image/jpeg" onChange={handleFileChange} /> */}
                        <input
                            type="file"
                            multiple
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }} // Hide the file input
                        />
                        <div className='bg-slate-100 px-4 py-3 rounded-lg flex items-center'>
                            <button type="button" className='btn btn-neutral mx-auto btn-sm' onClick={handleChooseFiles}>
                                Choose Files
                            </button>
                        </div>
                        <div className={`grid grid-cols-4 gap-3`}>
                            {selectedFiles.map((file, index) => (
                                <div key={index} className={`mt-5 relative`}>
                                    <img className="object-cover shadow-md" src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                                    {/* <button type="button" className='btn btn-small btn-error' onClick={() => handleDeleteImage(index)}>
                                Delete
                            </button> */}
                                    <button className="btn btn-circle btn-error btn-xs btn-outline absolute top-0 right-0" type="button" onClick={() => handleDeleteImage(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p>{message}</p>
                    <p className='text-red-700'>{error}</p>
                    <button type="submit" className={`btn btn-neutral mt-14`} disabled={false}>{loading ? "Adding..." : "Add Listing"}</button>
                    {/* <button type="submit" className={`btn btn-neutral mt-14 ${status !== "authenticated" && "loading loading-spinner"}`} disabled={status !== "authenticated"}>{loading ? "Adding..." : "Add Listing"}</button> */}
                </form>
            </div>

        </>
    );
};

function ListingSuccessPage({ setShowSuccessPage }) {
    return (
        <div>
            <div>
                <p>Your Listing has successfully posted!</p>
            </div>
            <div>
                <button onClick={() => { setShowSuccessPage(false); }}>Add another listing</button>
                <button><NavLink to="/user/dashboard">View all my listings</NavLink></button>
            </div>
        </div>
    );
}
