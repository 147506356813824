import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { generatePDFDocumentFromRef } from "../../../util/generatePDF";
import { GlobalContext } from "../../../context/globalContext";
import LanguageToggle from "../../../components/LanguageToggle/LanguageToggle";
// import LanguageToggle from "../../../components/LanguageToggle/LanguageToggle";




export default function MortgagePage() {
    const [inputs, setInputs] = useState({
        type: "interestOnly",
        price: null,
        deposit: null,
        term: 25,
        rate: 5.25
    });
    const [result, setResult] = useState({ status: "null", data: null });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const { isSmallScreen } = useContext(GlobalContext);
    const tableRef = useRef(null);
    const language = new URLSearchParams(window.location.search).get('language');
    const [showBreakdown, setShowbreakdown] = useState(false);
    const [isEnglish, setIsEnglish] = useState(new URLSearchParams(window.location.search).get('language') === 'chinese' ? false : true);


    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('language', isEnglish ? 'english' : 'chinese');
        window.history.pushState({}, '', url);
    }, [isEnglish]);

    function handleEstimateRent(e) {
        e.preventDefault();
        setError("");
        setLoading(true);
        const { type, price, deposit, term, rate } = inputs;
        if (!(price && deposit && term && rate)) {
            setError(isEnglish ? "Please complete all fields." : "请填写完整表格。");
            setLoading(false);
            return;
        }

        const result = calculateMortgage(type, price, deposit, term, rate);
        console.log(result);
        if (result) {
            setResult({
                status: "success",
                data: result
            });
            setLoading(false);
        }
    }

    function calculateMortgage(mortgageType, propertyPrice, deposit, termYears, annualInterestRate) {
        const loanAmount = propertyPrice - deposit;
        const monthlyInterestRate = annualInterestRate / 12 / 100;
        const totalPayments = termYears * 12;
        let monthlyPayment;
        let totalRepay = 0;
        let yearlyBreakdown = [];

        if (mortgageType === 'repayment') {
            // Calculate monthly payment for repayment mortgage
            monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
            for (let year = 1; year <= termYears; year++) {
                let remainingDebt = loanAmount;
                for (let month = 1; month <= year * 12; month++) {
                    let interestForThisMonth = remainingDebt * monthlyInterestRate;
                    let principalForThisMonth = monthlyPayment - interestForThisMonth;
                    remainingDebt -= principalForThisMonth;
                }
                yearlyBreakdown.push({ year: year, remainingDebt: remainingDebt });
            }
            totalRepay = monthlyPayment * totalPayments;
        } else if (mortgageType === 'interestOnly') {
            // Calculate monthly payment for interest-only mortgage
            monthlyPayment = loanAmount * monthlyInterestRate;
            for (let year = 1; year <= termYears; year++) {
                yearlyBreakdown.push({ year: year, remainingDebt: loanAmount });
            }
            totalRepay = monthlyPayment * totalPayments + (propertyPrice - deposit);
        } else {
            return "Invalid mortgage type";
        }

        return {
            monthlyPayment: monthlyPayment.toFixed(2),
            totalRepay: totalRepay.toFixed(2),
            yearlyBreakdown: yearlyBreakdown.map(item => ({ ...item, remainingDebt: item.remainingDebt.toFixed(2) }))
        };
    }





    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }


    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                try {

                    const doc = await generatePDFDocumentFromRef(tableRef.current);
                    if (doc) {
                        doc.save(`Mortgage Calculation.pdf`);
                        // const pdfBlob = doc.output('blob');
                        // const pdfUrl = URL.createObjectURL(pdfBlob);
                        // setPdfUrl(pdfUrl);

                        // !isSmallScreen && window.open(pdfUrl, '_blank');

                        setIsGeneratingPDF(false);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF, tableRef.current]);



    return (
        <>
            <Helmet>
                <title>Mortgage Calculator | Letting Engine</title>
                <meta
                    name="description"
                    content="Use our mortgage calculator for your property."
                />
                <meta
                    name="keywords"
                    content="residential property, real estate, london, mortgage calculator"
                />
            </Helmet>
            <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} />
            {/* <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} /> */}
            <div className={`flex flex-col items-center ${isEnglish ? " isEnglish" : "isChinese"}`}>
                {/* <div className={`flex flex-col items-center ${isEnglish ? " isEnglish" : "isChinese"}`}> */}

                <div ref={tableRef} className={`content-inner p-10 sm:p-16 to-print-mortgage sm:min-w-[600px]`}>
                    <form
                        onSubmit={handleEstimateRent}
                        onChange={() => { setError(""); setResult({ status: "null" }); setPdfUrl(""); }}
                        className="flex flex-col gap-6 text-secondary-dark text-nowrap"
                    >
                        <h1 className="mb-1 sm:mb-10 special-h1 font-semibold text-4xl">
                            <span className="english">Mortgage Calculator</span>
                            <span className="chinese">贷款计算器</span>
                        </h1>

                        <div className="flex justify-between gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700 ">
                                <span className="english">Type of mortgage:</span>
                                <span className="chinese">贷款种类:</span>
                            </span>
                            <div className="flex gap-4 sm:max-w-xs sm:w-full">

                                <label className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        value="interestOnly"
                                        checked={inputs.type === "interestOnly"}
                                        onChange={() => setInputs({ ...inputs, type: "interestOnly" })}
                                    />
                                    <span>{isEnglish ? "Interest-only" : "仅付利息"}</span>
                                </label>
                                <label className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        value="repayment"
                                        checked={inputs.type === "repayment"}
                                        onChange={() => setInputs({ ...inputs, type: "repayment" })}
                                    />
                                    <span>{isEnglish ? "Repayment" : "本息还款"}</span>
                                </label>
                            </div>
                        </div>

                        <label className="flex justify-between sm:items-center gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Property price:</span>
                                <span className="chinese">房产价格:</span>
                            </span>
                            <div className="sm:max-w-xs sm:w-full relative flex items-center">
                                <input
                                    className="input input-bordered sm:w-full sm:max-w-xs bg-transparent pl-6"
                                    type="text"
                                    value={inputs.price ? inputs.price.toLocaleString() : null}
                                    placeholder="500,000"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/,/g, '');
                                        setInputs({ ...inputs, price: value ? Number(value) : '' });
                                    }}
                                />
                                <span className="absolute text-main left-3 adj">£</span>
                            </div>
                        </label>

                        <label className="flex justify-between sm:items-center gap-4 flex-col sm:flex-row return-flex-row">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Deposit:</span>
                                <span className="chinese">首付:</span>
                            </span>
                            <div className="sm:max-w-xs sm:w-full relative flex items-center">
                                <input
                                    className="input input-bordered sm:w-full sm:max-w-xs bg-transparent pl-6"
                                    type="text"
                                    value={inputs.deposit ? inputs.deposit.toLocaleString() : null}
                                    placeholder="200,000"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/,/g, '');
                                        setInputs({ ...inputs, deposit: value ? Number(value) : '' });
                                    }}
                                />
                                <span className="absolute text-main left-3 adj">£</span>
                            </div>
                        </label>

                        <label className="flex justify-between sm:items-center gap-4 flex-col sm:flex-row return-flex-row relative">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Mortgage term:</span>
                                <span className="chinese">按揭期限:</span>
                            </span>
                            <input
                                className="input input-bordered sm:w-full sm:max-w-xs bg-transparent"
                                type="number"
                                value={inputs.term}
                                placeholder="25"
                                onChange={(e) => setInputs({ ...inputs, term: e.target.value })}
                            />
                            <span className="absolute text-main right-10">{isEnglish ? "Years" : "年"}</span>
                        </label>

                        <label className="flex justify-between sm:items-center gap-4 flex-col sm:flex-row return-flex-row relative">
                            <span className="font-semibold text-gray-700">
                                <span className="english">Interest rate:</span>
                                <span className="chinese">利率:</span>
                            </span>
                            <input
                                className="input input-bordered sm:w-full sm:max-w-xs bg-transparent"
                                type="number"
                                value={inputs.rate}
                                placeholder="5.25"
                                onChange={(e) => setInputs({ ...inputs, rate: e.target.value })}
                            />
                            <span className="absolute text-main right-10">%</span>
                        </label>

                        <button className="btn pt-0 mt-0 bg-main" type="submit">
                            {loading ?
                                (
                                    <>
                                        <span className="english">Calculating...</span>
                                        <span className="chinese">为您计算中...</span>
                                    </>
                                )
                                : (
                                    <>
                                        <span className="english">Calculate</span>
                                        <span className="chinese">计算</span>
                                    </>
                                )}
                        </button>
                    </form>
                    <div className="mt-16 flex flex-col">
                        {error && <p className="font-semibold mb-4 text-main">{error}</p>}
                        {result && result.status === "success" && result.data &&
                            <>
                                <h3 className="font-semibold mb-4 text-main text-3xl">
                                    <span>{isEnglish ? "Monthly payment: " : "月还款额： "}</span>
                                    <span>£{(+result.data.monthlyPayment).toLocaleString('en-US')}</span>
                                </h3>
                                <p className="text-xl">
                                    <span>{isEnglish ? "Total repay: " : "总还款额："}</span>
                                    <span>£{(+result.data.totalRepay).toLocaleString()}</span>
                                </p>
                                <div className="opacity-60">
                                    <p>
                                        <span>- {isEnglish ? "Capital: " : "本金："}</span>
                                        <span>£{(+inputs.price - +inputs.deposit).toLocaleString()}</span>
                                    </p>
                                    <p>
                                        <span>- {isEnglish ? "Interest: " : "利息："}</span>
                                        <span>£{(+result.data.totalRepay - (+inputs.price - +inputs.deposit)).toLocaleString()}</span>
                                    </p>
                                </div>
                                <p className="hover:cursor-pointer flex items-center text-lg mt-8 mb-3 gap-2 text-main"
                                    onClick={() => { setShowbreakdown(!showBreakdown); }}>
                                    <span class="material-symbols-outlined">{showBreakdown ? "expand_less" : "expand_more"}</span>
                                    {showBreakdown ? (isEnglish ? "Hide" : "隐藏") : (isEnglish ? "Show" : "展开")}{isEnglish ? " breakdown" : "明细"}
                                </p>
                                {showBreakdown &&

                                    <table className='table w-full mortgage-result-table'>
                                        <thead className="relative">
                                            <tr >
                                                <th>{isEnglish ? "Year" : "年份"}</th>
                                                <th>{isEnglish ? "Remaining Debt" : "剩余债务"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result.data.yearlyBreakdown.map((item) => (
                                                <tr key={item.year} >
                                                    <td>{item.year}</td>
                                                    <td>£{(+item.remainingDebt).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }

                                <div className='mt-20 mb-20 opacity-50'>
                                    <p>{isEnglish ? "The amounts in the table are for reference only and are subject to actual occurrence." : "表中的金额以实际发生为准，以上数据仅供参考。"}</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {!error && result && result.status === "success" && result.data &&
                    <div className="mb-20 flex flex-col items-center ">
                        <button className="bg-main text-white px-4 py-2 rounded-lg mt-0 self-center mb-10 sm:mb-60 " onClick={handleDownloadPDF}>
                            {isGeneratingPDF ? <span>{isEnglish ? "Downloading PDF..." : "PDF 下载中..."}</span> : (
                                <span>{isEnglish ? "Download PDF" : "下载 PDF"}</span>
                            )}
                        </button>
                        {isSmallScreen && pdfUrl && (
                            <div className="pdf-download-link">
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    <span>{isEnglish ? "Open PDF" : "打开 PDF"}</span>
                                </a>
                            </div>
                        )}
                    </div>
                }
            </div >
        </>

    );
}

