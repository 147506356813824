import React from 'react';

export default function LanguageToggle({ language, setLanguage }) {
    return (
        <div
            className='absolute top-[60px] sm:top-[102px] right-[20px] sm:right-[50px] flex gap-3 items-center text-main hover:cursor-pointer'
            onClick={() => { setLanguage(!language); }}
        >
            <p>中文</p>
            <div className='border border-main rounded-full w-14 flex flex-col'>
                <div className={`bg-main h-5 w-5 m-1 rounded-full ${language ? "self-end" : "self-start"}`}></div>
            </div>
            <p>English</p>
            {/* {language ? "Chinese" : "English"} */}
        </div>
    );
};